import React, { useEffect, useState, useCallback } from 'react';
import { createStyles } from 'utils/createStyle';
import { Btn } from 'components/btn/Btn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCopy,
    faCheck,
    faEnvelope,
    faSignOut,
    faCommentAltLines,
} from '@fortawesome/pro-regular-svg-icons';
import { colors } from 'styles/settings';
import { createEnrollmentCodeFromOrg } from 'common/api/crm/family/service';
import Stars from 'media/images/stars.svg';
import LogoIconLightBg from 'media/images/LogoIconLightBg.svg';
import { useRootSelector } from 'rootStore';
import { selectDefaultOrganization, selectHomeDetails } from 'common/features/store/duck/home/duck';
import Skeleton from 'react-loading-skeleton';
import { getEnrollmentUrl } from 'utils/getEnrollmentUrl';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import ResponsiveContainer, {
    ContainerType,
} from 'components/responsive-containers/ResponsiveContainer';
import { useP2PInviteSettings } from '../../coordinator-dashboard/p2p-invite-settings/P2PInviteSettings';
import { logP2PInviteFamily } from 'config/analytics';
import useQueryParams from 'hooks/useQueryParams';

const styles = createStyles({
    header: 'text-brand-dark font-semibold text-2xl mb-10 text-center',
    starsLeft: ['self-start pr-2', { marginTop: -5 }],
    starsRight: [
        'self-end translate-x -rotate-180 pl-2',
        {
            transform: 'rotate(180deg)',
            marginBottom: -8,
        },
    ],
    pageContainer: [
        {
            margin: '0 auto',
            maxWidth: 480,
            '@media (max-width: 640px)': { maxWidth: 'auto' },
        },
    ],
    contentContainer: [
        'flex flex-col pt-4',
        {
            maxWidth: 480,
            '@media (max-width: 640px)': { maxWidth: 'auto' },
        },
    ],
    shareYourLink: 'text-grey-darkest font-semibold',
    copyButton: [
        'flex font-semibold text-sm text-brand text-left transition-shadow align-end',
        {
            '&:disabled': {
                color: colors.success,
                borderColor: colors.white,
                pointerEvents: 'none',
            },
            '&:active': {
                transition: 'none',
                boxShadow: 'none',
            },
            '&:focus': {
                transition: 'none',
                boxShadow: 'none',
                outline: 'none',
            },
        },
    ],
    shareMobileIcon: [
        'self-end translate-x',
        {
            transform: 'rotate(-90deg)',
        },
    ],

    subHeader: 'text-brand-dark font-semibold text-xl mb-1 text-center px-3 sm:px-4',
    btn: ['w-full sm:w-auto mt-3 mb-5'],
});

export enum P2pSources {
    Settings = 'settings',
    Header = 'header',
}

const parseP2pInviteSource = (sourceParam: string | null) => {
    let source = P2pSources.Settings;

    // Validate that we're getting the right one, otherwise we'll just default to `settings`
    if (sourceParam && (sourceParam == P2pSources.Header || sourceParam == P2pSources.Settings)) {
        source = sourceParam;
    }

    return source;
};

const InviteFriendsFamily = () => {
    const params = useQueryParams();
    const source = parseP2pInviteSource(params.get('source'));
    const defaultOrganization: any = useRootSelector(selectDefaultOrganization);
    const selectedOrganizationId = defaultOrganization?.id as string;
    const [isCopied, copyText] = useCopyToClipboard(5000);
    const homeDetails = useRootSelector(selectHomeDetails);
    const [inviteUrl, setInviteUrl] = useState('');
    const { isP2PInviteEnabled } = useP2PInviteSettings();

    const emailSubject = `${homeDetails?.profile.firstName} invites you to earn for ${defaultOrganization.name}`;
    const emailBodyTop = `${defaultOrganization.name} uses RaiseRight to fundraise the easy way—you can earn over $1,000 each year by using gift cards to pay for everyday expenses and shopping online through the app or website. It’s convenient, and it works!`;
    const emailBodyBottom = `Sign up using this referral link: ${inviteUrl}`;

    useEffect(() => {
        (async () => {
            if (selectedOrganizationId) {
                const result = await createEnrollmentCodeFromOrg(selectedOrganizationId);
                if (!result.error) {
                    if (result.data.code) {
                        setInviteUrl(getEnrollmentUrl(result.data.code));
                    }
                }
            }
        })();
    }, [selectedOrganizationId]);

    const onCopyCode = useCallback(() => {
        copyText(inviteUrl);
    }, [copyText, inviteUrl]);

    const shareData = {
        title: emailSubject,
        url: inviteUrl,
    };

    if (!isP2PInviteEnabled) {
        return <div />;
    }

    return (
        <div className={styles.pageContainer}>
            <div className={styles.contentContainer}>
                <h1 className={styles.header}>Invite Friends & Family</h1>

                <div className="flex self-center">
                    <img
                        className={styles.starsLeft}
                        src={Stars}
                        alt="Raise Right Logo"
                        width="30"
                        height="43"
                    />
                    <img src={LogoIconLightBg} alt="Raise Right Logo" width="95" height="87" />
                    <img
                        className={styles.starsRight}
                        src={Stars}
                        alt="Raise Right Logo"
                        width="30"
                        height="43"
                    />
                </div>
                <div className="px-0 sm:px-4 mt-8 flex flex-col items-center">
                    <h3 className={styles.subHeader}>
                        Share RaiseRight with friends and family to help them earn for your
                        organization.
                    </h3>
                    <p className="text-grey-medium text-base text-center pt-5 px-0 mb-4">
                        <strong>Note:</strong> Please do not share this link on public websites or
                        social media pages, doing so may expose your organization to fraudulent
                        activity.
                    </p>
                </div>
            </div>
            <div className={styles.contentContainer}>
                {inviteUrl ? (
                    <div className="px-0 sm:px-3">
                        <h5 className={styles.shareYourLink}>Share Your Link</h5>
                        <div className="mt-3 border border-grey-3 flex ju px-2 py-2.5 rounded">
                            <span className="w-11/12 sm:w-9/12 text-grey-3 overflow-ellipsis whitespace-nowrap overflow-hidden pr-2">
                                {inviteUrl}
                            </span>

                            <div className="w-1/12 sm:w-3/12 flex items-center justify-end">
                                <ResponsiveContainer For={ContainerType.desktop}>
                                    <button
                                        className={styles.copyButton}
                                        disabled={isCopied}
                                        onClick={() => {
                                            logP2PInviteFamily('copy', source);
                                            onCopyCode();
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            color={isCopied ? colors.success : colors.brand}
                                            style={{ fontSize: isCopied ? '18px' : '20px' }}
                                            icon={isCopied ? faCheck : faCopy}
                                            aria-label="Copy Invite"
                                        />
                                        <span className="ml-2">
                                            {isCopied ? 'Link Copied' : ''}
                                        </span>
                                    </button>
                                </ResponsiveContainer>

                                <ResponsiveContainer For={ContainerType.mobile}>
                                    <button
                                        className={styles.copyButton}
                                        disabled={isCopied}
                                        onClick={async () => {
                                            await navigator.share(shareData);
                                            logP2PInviteFamily('share', source);
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            className={styles.shareMobileIcon}
                                            color={colors.brand}
                                            style={{ fontSize: '20px' }}
                                            icon={faSignOut}
                                            aria-label="Share Invite"
                                        />
                                    </button>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Skeleton height="80" width="200" />
                )}
            </div>
            <div className={styles.contentContainer}>
                <div className="flex self-center whitespace-nowrap">
                    <Btn
                        onClick={() => {
                            window.location.href = `mailto:?subject=${emailSubject}&body=${emailBodyTop}%0D%0D${emailBodyBottom}`;
                            logP2PInviteFamily('email', source);
                        }}
                        className={styles.btn}
                    >
                        <FontAwesomeIcon
                            color={colors.white}
                            style={{ fontSize: '18px', marginBottom: -1 }}
                            icon={faEnvelope}
                            aria-label="Send Email Invite"
                        />
                        <span className="pl-2">Email</span>
                    </Btn>
                    <ResponsiveContainer For={ContainerType.mobile}>
                        <Btn
                            onClick={() => {
                                window.open(
                                    `sms:?&body=${emailBodyTop}%0D%0D${emailBodyBottom}`,
                                    '_self'
                                );
                                logP2PInviteFamily('text', source);
                            }}
                            className={styles.btn}
                            style={{ marginLeft: 5 }}
                        >
                            <FontAwesomeIcon
                                color={colors.white}
                                style={{ fontSize: '18px', marginBottom: -2 }}
                                icon={faCommentAltLines}
                                aria-label="Send Text Invite"
                            />
                            <span className="pl-2">Text</span>
                        </Btn>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
};

export default InviteFriendsFamily;
