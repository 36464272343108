import React from 'react';
import classNames from 'classnames';
import * as Tabs from '@radix-ui/react-tabs';

import { noop } from 'common/utils';
import { createStyles } from '../../../utils/createStyle';

import { LinkBtn } from 'components/link-btn';
import { TabProps } from 'features/product/components/ProductDetailScreenTab';

const style = createStyles({
    btn: [
        'text-center text-brand',
        { padding: '10px 20px', borderRadius: '50px' },
        { '@media (min-width: 640px)': { padding: '8px 24px' } },
    ],
    btnIcon: 'hidden sm:flex',
    btnLinkContainer: 'flex items-center',
    btnLabel: ['text-base text-brand text-center leading-4 font-semibold', { marginLeft: '8px' }],
});

export interface ShopNavOptionProps extends Omit<TabProps, 'offer'> {
    onClick?: () => void;
    isNew?: boolean;
}

export const ShopNavOption = ({ onClick = noop, isNew, ...props }: ShopNavOptionProps) => {
    const { selected, icon, tabId, title } = props;
    return (
        <Tabs.Tab className={classNames(style.btn, selected && 'bg-pale-blue')} value={tabId}>
            <LinkBtn style={{ textDecoration: 'none' }} onClick={onClick}>
                <div className={style.btnLinkContainer}>
                    <div className={style.btnIcon}>{icon}</div>
                    <div className={style.btnLabel}>{title}</div>
                </div>
            </LinkBtn>
        </Tabs.Tab>
    );
};
