import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { isInIframe, mainWebsiteOrigin } from 'utils';

export const useScrollToTop = () => {
    const history = useHistory();
    useEffect(() => {
        if (isInIframe()) {
            window.parent.postMessage({ type: 'scrollToTop' }, mainWebsiteOrigin());
            return;
        }
        window.scrollTo(0, 0);
        const unsubscribe = history.listen(() => window.scrollTo(0, 0));
        return () => unsubscribe();
    }, [history]);
};
