import React from 'react';
import { useDispatch } from 'react-redux';
import { favoriteBrandThunk, unfavoriteBrandThunk } from 'common/features/store/duck/brands/duck';
import { useRootSelector } from 'common/features/featuresReducer';
import './BrandCardSlim.scss';
import { FavoriteId } from 'common/api/e-comm/models/FavoriteBrandDataModel';

export const useFavoriteControls = (brandId: FavoriteId) => {
    const dispatch = useDispatch<any>();
    const isFavorite = useRootSelector((s) => {
        const { favorites } = s.store.brands;
        return favorites !== null && favorites.includes(`${brandId}`);
    });

    const onClick = (e: React.SyntheticEvent) => {
        e.stopPropagation();

        if (isFavorite) {
            dispatch(unfavoriteBrandThunk(brandId));
        } else {
            dispatch(favoriteBrandThunk(brandId));
        }
    };

    return { isFavorite, onClick };
};
