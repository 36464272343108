import { createSlice } from 'common/modules/create-slice';
import { MerchantDto, PaginatedMerchantsDto } from './schema';
import { FeaturesState } from 'common/features/featuresReducer';
import { Dispatch } from 'redux';
import { createSelector } from 'reselect';
import { getMerchantDetails, getMerchantsPaginated } from 'common/api/local-dining';
import { SavedCreditCard } from 'common/api/e-comm/models/CreditCard';
import {
    TokenExFlow,
    getHmac,
    BaseTokenExPayload,
    registerUniversalToken,
    getSavedCreditCards,
    deleteCloLinkedCreditCard,
    enrollExistingCreditCardWithClo,
    getZipCodeFromCrmForUser,
} from 'common/api/e-comm/credit-cards/service';
import { setCardEnrolledInRewardsNetwork, updateAccount } from '../account/duck';
import { selectHomeDetails } from '../home/duck';

const MockLinkedCards = {};

export enum LocalDiningSearchMethod {
    Default = 'default',
    SearchBar = 'search_bar',
}

export enum LocalDiningSearchLocation {
    OrganizationLocation = 'organization_location',
    CurrentLocation = 'current_location',
    City = 'city_state',
    ZipCode = 'zip_code',
}

export interface LocalDiningState {
    cardsLinked: Record<string, SavedCreditCard>; // List of linked credit cards
    cardIds: SavedCreditCard['id'][];
    manuallyTypedLocation: string; // manually entered location (City, State or Zip Code)
    userLocation: string;
    // value used for initial SEARCH upon landing on the screen.
    // This value is not meant to be rendered.
    defaultLocation: string;
    locationGPS: { latitude: number; longitude: number } | null; // GPS data retrieved from RN Location Api
    useCurrentLocation: boolean;
    searchResults?: PaginatedMerchantsDto;
    searching: boolean;
    searchMethod: LocalDiningSearchMethod | null;
    searchLocation: LocalDiningSearchLocation | null;
    hasRequestedLocationTracker: boolean;
    searchRequest?: MerchantsRequestBody;
    isDown: boolean;
}

// variable to use to make the newly added card to flash
// used in LocalDiningLInkedCardsScreen.tsx to make a flashing
// animation
export const cardToFlash = { id: 0 };
export const pagesLoaded: { pages: number[] } = { pages: [] };

const initialState: LocalDiningState = {
    cardsLinked: MockLinkedCards,
    cardIds: [],
    manuallyTypedLocation: '',
    userLocation: '',
    defaultLocation: '',
    locationGPS: null,
    useCurrentLocation: false,
    searchResults: undefined,
    searching: true,
    hasRequestedLocationTracker: false,
    searchRequest: undefined,
    isDown: false,
    searchLocation: null,
    searchMethod: LocalDiningSearchMethod.Default,
};

const localDiningSlice = createSlice(initialState, 'CARD_LINKED_OFFERS');
export const updateLocalDiningSlice = localDiningSlice.update;

export const resetLocalDining = () => localDiningSlice.update(initialState);

export const setLocation = localDiningSlice.configureAction(
    'SET_MANUALLY_TYPED_LOCATION',
    (location: string) => (state) => ({
        ...state,
        manuallyTypedLocation: location,
    })
);

export const setLocationGPS = localDiningSlice.configureAction(
    'SET_LOCATION_GPS',
    (locationGPS: { latitude: number; longitude: number }) => (state) => ({
        ...state,
        locationGPS,
    })
);

export const setUseCurrentLocation = localDiningSlice.configureAction(
    'SET_USE_CURRENT_LOCATION',
    (useCurrentLocation: boolean) => (state) => ({
        ...state,
        useCurrentLocation,
    })
);

export const setSearchLocation = localDiningSlice.configureAction(
    'SET_SEARCH_LOCATION',
    (nextSearchLocation: LocalDiningSearchLocation) => (state) => {
        return {
            ...state,
            searchLocation: nextSearchLocation,
        };
    }
);

export const setSearchMethod = localDiningSlice.configureAction(
    'SET_SEARCH_METHOD',
    (nextSearchMethod: LocalDiningSearchMethod) => (state) => {
        return {
            ...state,
            searchMethod: nextSearchMethod,
        };
    }
);

export type MerchantsRequestBody = {
    longitude?: number;
    latitude?: number;
    cityState?: string;
    zip?: string;

    // fields only used during scroll-pagination
    currentPage: number;
};

// TODO(Diego): Maybe we turn this into a thunk? Not sure.
export const merchantById = async (id: MerchantDto['id']) => {
    return await getMerchantDetails(id);
};

const searchMerchants = async (requestBody: MerchantsRequestBody) => {
    let queryParams = '';
    if (requestBody.cityState) {
        queryParams = `cityState=${encodeURIComponent(requestBody.cityState)}`;
    } else if (requestBody.zip) {
        queryParams = `zipCode=${requestBody.zip}`;
    } else if (requestBody.latitude && requestBody.longitude) {
        queryParams = `latitude=${requestBody.latitude}&longitude=${requestBody.longitude}`;
    }
    const result = await getMerchantsPaginated(queryParams, 100, requestBody.currentPage);

    return result;
};

export const searchMerchantsGetNextPageThunk =
    () => async (dispatch: Dispatch, getState: () => FeaturesState) => {
        try {
            const { searchRequest, searchResults } = getState().store.localDining;
            const currentPage = searchResults?.currentPage || 0;
            const totalPages = searchResults?.totalPages || 0;
            const nextPage = currentPage + 1;
            const pageAlreadyLoaded = pagesLoaded.pages.includes(nextPage);
            if (searchRequest && currentPage < totalPages && !pageAlreadyLoaded && searchResults) {
                pagesLoaded.pages.push(nextPage);
                const result = await searchMerchants({
                    ...searchRequest,
                    currentPage: nextPage,
                });

                if (result.data) {
                    const updatePayload: Partial<LocalDiningState> = {
                        searchResults: {
                            ...result.data,
                            merchants: [...searchResults.merchants, ...result.data.merchants],
                        },
                    };

                    dispatch(localDiningSlice.update(updatePayload as LocalDiningState));
                }
            }
        } catch (e) {
            dispatch(localDiningSlice.update({ searching: false }));
        }
    };

export const searchMerchantsThunk =
    (request: MerchantsRequestBody, saveLocationAsUserLocation?: boolean) =>
    async (dispatch: Dispatch) => {
        try {
            let updatePayload: Partial<LocalDiningState> = {
                searching: true,
                searchRequest: request,
                isDown: false,
            };
            pagesLoaded.pages = [1];

            if (saveLocationAsUserLocation === undefined) {
                updatePayload['manuallyTypedLocation'] = request.cityState ?? request.zip;
                updatePayload['useCurrentLocation'] = false;
            }

            dispatch(localDiningSlice.update(updatePayload as LocalDiningState));
            const result = await searchMerchants(request);

            if (result.data) {
                updatePayload = {
                    searchResults: result.data,
                    searching: false,
                    isDown: false,
                };

                if (saveLocationAsUserLocation) {
                    updatePayload['userLocation'] = result.data.search;
                }

                dispatch(localDiningSlice.update(updatePayload as LocalDiningState));
            } else {
                dispatch(
                    localDiningSlice.update({
                        searchRequest: undefined,
                        searchResults: undefined,
                        searching: false,
                        isDown: true,
                    })
                );
            }
        } catch (e) {
            dispatch(localDiningSlice.update({ searching: false }));
        }
    };

export const saveCardIds = localDiningSlice.configureAction(
    'SAVE_CARD_IDS',
    (ids: SavedCreditCard['id'][]) => (state) => ({
        ...state,
        cardIds: ids,
    })
);

const addNewLinkedCard_ = localDiningSlice.configureAction(
    'SET_NEW_LINKED_CARD',
    (cardId: SavedCreditCard['id']) => (state) => ({
        ...state,
        cardIds: [cardId, ...state.cardIds],
    })
);

export type LocalDiningCreditCardFormData = {
    name: string;
    cardNumber: string;
    zipCode: string;
    termsAndConditions: null;
};

export enum CardLinkingError {
    // NOTE: Only to be used for lower-environments to aid testing.
    TOKENEX_LUHN_INCOMPATIBLE = '2001: Input data not Luhn compatible',
    Generic = "We're unable to link your card. Please try again.",
}

export const addNewLinkedCard =
    (card: LocalDiningCreditCardFormData, tokenExId: string, tokenExUrl: string) =>
    async (dispatch: Dispatch, getState: () => FeaturesState) => {
        const state = getState().store;
        const data = await getHmac();
        const userProfile = state.home.homeDetails?.profile;
        const firstName = userProfile!.firstName;
        const lastName = userProfile!.lastName;
        const email = userProfile!.email;
        const isEmailOptIn = userProfile!.marketingOptIn;
        let error: CardLinkingError | undefined = CardLinkingError.Generic;

        if (data.token) {
            const tokenExResponse = await TokenExFlow(tokenExUrl, {
                ...BaseTokenExPayload,
                tokenexid: tokenExId,
                timestamp: data.timestamp,
                authenticationKey: data.token,
                data: card.cardNumber.replace(/\s/g, ''),
            });

            if (tokenExResponse.Success && tokenExResponse.Token) {
                // call ecomm to register the card
                const response = await registerUniversalToken({
                    universalToken: tokenExResponse.Token,
                    firstName: firstName,
                    lastName: lastName,
                    zipCode: card.zipCode,
                    email: email,
                    isEmailOptIn: isEmailOptIn,
                });

                if (response.data) {
                    const response = await getSavedCreditCards();

                    if (response.data) {
                        const newCardId = response.data[0].id;
                        dispatch(
                            updateAccount({
                                savedCreditCards: response.data,
                            })
                        );
                        setTimeout(() => {
                            dispatch(addNewLinkedCard_(newCardId));
                        }, 300);
                        cardToFlash.id = newCardId;
                    }
                    error = undefined;
                }
            } else {
                error = CardLinkingError.TOKENEX_LUHN_INCOMPATIBLE;
                dispatch(
                    updateLocalDiningSlice({
                        cardsLinked: state.localDining.cardsLinked,
                        cardIds: state.localDining.cardIds,
                    })
                );
            }
        }

        return error;
    };

export const toggleCardLinkedState =
    (card: SavedCreditCard) => async (dispatch: Dispatch, getState: () => FeaturesState) => {
        const creditCards = getState().store.account.savedCreditCards;
        const index = creditCards?.findIndex((savedCard) => card.id === savedCard.id);
        if (creditCards && index !== undefined && index > -1) {
            const savedCreditCards = [...creditCards];
            savedCreditCards![index!] = card;
            dispatch(
                updateAccount({
                    savedCreditCards,
                })
            );

            const unenrollOnly = true;
            const result = await deleteCloLinkedCreditCard(card.id, unenrollOnly);

            // If it fails undo the change
            if (result.error) {
                dispatch(
                    updateAccount({
                        savedCreditCards: creditCards,
                    })
                );
            }
            return result;
        }
        return {};
    };

const deleteLocalDiningCardFromStore = localDiningSlice.configureAction(
    'DELETE_LINKED_CARD',
    (id: SavedCreditCard['id']) => (state) => {
        return {
            ...state,
            cardIds: state.cardIds.filter((cardId) => cardId !== id),
        };
    }
);

export const enrollExistingIxoPayCardInRewardsNetwork =
    (cardId: number) => async (dispatch: Dispatch, getState: () => FeaturesState) => {
        const state = getState();
        const homeDetails = selectHomeDetails(state);

        if (!homeDetails?.profile || !cardId) {
            return { error: true };
        }

        try {
            const userZip = await getZipCodeFromCrmForUser();
            const defaultZip = homeDetails!.defaultOrganization!.address!.zipCode;
            const zipCode = userZip || defaultZip;
            const response = await enrollExistingCreditCardWithClo({
                creditCardId: cardId,
                email: homeDetails!.profile.email,
                firstName: homeDetails!.profile.firstName,
                lastName: homeDetails!.profile.lastName,
                zipCode,
            });
            if (response.data) {
                dispatch(
                    setCardEnrolledInRewardsNetwork({
                        id: cardId,
                        status: true,
                    })
                );
            }
            return response;
        } catch (e) {
            return { error: 'Unable to enroll card. ' + e };
        }
    };

export const deleteLocalDiningCard = (id: SavedCreditCard['id']) => async (dispatch: Dispatch) => {
    const response = await deleteCloLinkedCreditCard(id);

    if (response.data) {
        dispatch(deleteLocalDiningCardFromStore(id));
    }

    return response;
};

// selectors
const EMPTY_ARRAY: unknown[] = [];
export const selectUserLocationGPS = (state: FeaturesState) => state.store.localDining.locationGPS;
export const selectManuallyTypedLocation = (state: FeaturesState) =>
    state.store.localDining.manuallyTypedLocation;
export const selectUserLocation = (state: FeaturesState) => state.store.localDining.userLocation;
export const selectUseCurrentLocation = (state: FeaturesState) =>
    state.store.localDining.useCurrentLocation;
export const selectHasRequestedLocationTracker = (state: FeaturesState) =>
    state.store.localDining.hasRequestedLocationTracker;
export const selectLinkedCardIds = createSelector(
    (state: FeaturesState) => state.store.localDining.cardIds,
    (cardIds) => {
        return cardIds || EMPTY_ARRAY;
    }
);
export const selectNumberOfCardsLinked = (state: FeaturesState) => {
    return state.store.localDining.cardIds.length;
};
export const selectLocalDiningSearchMethod = (state: FeaturesState) => {
    return state.store.localDining.searchMethod;
};
export const selectLocalDiningSearchLocation = (state: FeaturesState) => {
    return state.store.localDining.searchLocation;
};

export const localDiningReducer = localDiningSlice.reducer;
