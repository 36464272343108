import { SavedCreditCard, CreditCardType } from '../models/CreditCard';
import { PaymentProviderResponse } from '../orders/service';

import { withTokensIfNeeded } from 'common/api/utils/withTokensIfNeeded';
import { CREDIT_CARDS_URL, FAMILY_ADDRESS_URL, SECURED_E_COMM_URL } from 'common/api/config';
import { ThreeDSResponse, ThreeDSTransactionError } from 'common/modules/3dsecure';
import { UserCartType } from '../cart';
import { APIResponse, UnsuccessfulResponse } from 'common/api/models';
import { CardLinkingError } from 'common/features/store/duck/local-dining/duck';

export const getSavedCreditCards = () => withTokensIfNeeded<SavedCreditCard[]>(CREDIT_CARDS_URL);

export interface CreditCardTransactionTokenResponse {
    token: string | null;
    clientIpAddress: string | null;
    customerId: number;
    cartReferenceId: number;
}

export const getCreditCardTransactionToken = (amount: number, cartType?: UserCartType) =>
    withTokensIfNeeded<CreditCardTransactionTokenResponse>(`${CREDIT_CARDS_URL}/transactionToken`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount, userCartType: cartType || UserCartType.DEFAULT }),
    });

export const deleteCreditCard = (id: number) =>
    withTokensIfNeeded<null>(`${CREDIT_CARDS_URL}/${id}`, {
        method: 'DELETE',
    });

export interface ThreeDSecureTokenResponse {
    token: string;
    expireDateTime: string;
}

export const get3DSecureToken = () =>
    withTokensIfNeeded<ThreeDSecureTokenResponse>(`${CREDIT_CARDS_URL}/3DSecure`);

export interface CreditCardProviderResponseLogEntry {
    payment3DSecureResponse: ThreeDSTransactionError | ThreeDSResponse | null;
    paymentProviderResponse: PaymentProviderResponse;
    cartId: string;
    cardType: CreditCardType;
}

export const logCreditCardFailure = (logEntry: CreditCardProviderResponseLogEntry) =>
    withTokensIfNeeded<CreditCardTransactionTokenResponse>(
        `${CREDIT_CARDS_URL}/CreditCardPaymentProviderResponse?api-version=2`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(logEntry),
        }
    );

export const voidPayment = (transactionId: string) =>
    withTokensIfNeeded(`${CREDIT_CARDS_URL}/payment/void`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ transactionId }),
    });

export type ValidateCreditCardBinResponse = {
    bin: string;
    isValid: boolean;
    isCredit: boolean;
    isDebit: boolean;
};

export const validateCardBinWithTokenX = async (
    cardNumber: string,
    tokenExId: string | undefined,
    url: string | undefined
) => {
    const _cardNumber = cardNumber.replace(/\s/g, '');
    if (!tokenExId || !url) {
        throw new Error('tokenx values missing from config!');
    }

    const data = await getHmac('ASCIITOKEN');
    const errorResponse: UnsuccessfulResponse<any> = {
        error: {
            response: null,
            error: {
                name: 'Error',
                message: CardLinkingError.Generic,
            },
        },
    };

    const tokenExResponse = await TokenExFlow(url, {
        ...BaseTokenExPayload,
        tokenScheme: 'ASCIITOKEN',
        tokenexid: tokenExId,
        timestamp: data.timestamp,
        authenticationKey: data.token,
        data: _cardNumber.substring(0, 8),
    });

    if (tokenExResponse.Success && tokenExResponse.Token) {
        const response = await withTokensIfNeeded<ValidateCreditCardBinResponse>(
            `${CREDIT_CARDS_URL}/validate-bin?api-version=2`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: tokenExResponse.Token }),
            }
        );
        return response;
    }
    return errorResponse;
};

export const validateCardBin = (bin: string) => {
    // remove all spaces and only include the first 8 digits of the bin
    const binPayload = bin.replace(/ /g, '').substring(0, 8);

    if (binPayload?.length !== 8) {
        return {
            data: {
                bin: bin,
                isValid: false,
                isDebit: false,
                isCredit: false,
            },
        } as unknown as APIResponse<ValidateCreditCardBinResponse>;
    }

    return withTokensIfNeeded<ValidateCreditCardBinResponse>(`${CREDIT_CARDS_URL}/validate-bin`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ bin: binPayload }),
    });
};

type HmacResponse = {
    token: string;
    timestamp: string;
};

export const getHmac = async (scheme?: string) => {
    const tokenScheme = scheme || 'PCI';
    const response = await withTokensIfNeeded<HmacResponse>(
        `${SECURED_E_COMM_URL}/tokenauthorization/tokenex?tokenScheme=${tokenScheme}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );
    return response.data ? response.data : { token: '', timestamp: '' };
};

type TokenExPayload = {
    tokenexid: string | null;
    timestamp: string; // todays date
    authenticationKey: string; //hmac
    data: string; // card number
    tokenScheme: 'PCI' | 'ASCIITOKEN';
    useExtendedBin: boolean;
};

export const BaseTokenExPayload: TokenExPayload = {
    tokenexid: '',
    timestamp: '',
    authenticationKey: '', //hmac
    data: '', // card number
    tokenScheme: 'PCI',
    useExtendedBin: false,
};

type TokenExResponse = {
    Error: string | null;
    Success: boolean;
    ReferenceNumber: string | null;
    Token: string | null;
    TokenHMAC: string | null;
    FirstEight: string | null;
};

export const TokenExFlow = async (url: string, body: TokenExPayload): Promise<TokenExResponse> => {
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });
        return await response.json();
    } catch (e) {
        // just return a stub and mark it as
        // Success:false
        return {
            Error: null,
            Success: false,
            ReferenceNumber: null,
            Token: null,
            TokenHMAC: null,
            FirstEight: null,
        };
    }
};

type RegisterUniversalTokenPayload = {
    universalToken: string;
    firstName: string;
    lastName: string;
    zipCode: string;
    email: string;
    isEmailOptIn: boolean;
};

type EcommError = { errorCode: string; errorMessage: string };

export const registerUniversalToken = async (payload: RegisterUniversalTokenPayload) => {
    return withTokensIfNeeded<any, EcommError>(`${CREDIT_CARDS_URL}/register-ixopay`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });
};

export const deleteCloLinkedCreditCard = async (
    creditCardId: SavedCreditCard['id'],
    unenrollOnly = false
) => {
    const params = unenrollOnly ? '?unenrollOnly=true' : '';
    return withTokensIfNeeded<any, EcommError>(`${CREDIT_CARDS_URL}/${creditCardId}${params}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const enrollExistingCreditCardWithClo = async ({
    creditCardId,
    firstName,
    lastName,
    zipCode,
    email,
}: {
    creditCardId: SavedCreditCard['id'];
    firstName: string;
    lastName: string;
    zipCode: string;
    email: string;
}) => {
    return withTokensIfNeeded<any, EcommError>(`${CREDIT_CARDS_URL}/enroll-token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            FamilyAccountPaymentTokenId: creditCardId,
            FirstName: firstName,
            LastName: lastName,
            ZipCode: zipCode,
            Email: email,
            EmailOptIn: true,
        }),
    });
};

export const getZipCodeFromCrmForUser = async () => {
    const userZipResponse = withTokensIfNeeded<any, EcommError>(FAMILY_ADDRESS_URL, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const userZipFromFirstAddress = (userZipResponse as any)?.data?.[0]?.zipCode || null;

    return userZipFromFirstAddress;
};
