import React, { useState, useEffect, useCallback } from 'react';
import { keyframes } from 'glamor';
import classNames from 'classnames';
import * as RuiDialog from '@radix-ui/react-dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import ResponsiveContainer, { ContainerType } from '../responsive-containers/ResponsiveContainer';

import { createStyles } from 'utils/createStyle';
import { defaultEaseInOutBackAnimation, fadeIn, fadeOut, EASE_OUT_QUART } from 'styles/easing';
import useMediaQueries from 'hooks/useMediaQueries';

const DEFAULT_AUTO_CLOSE_DURATION = 5000;

export interface MenuItem {
    text: string;
    type: 'item' | 'header';
    onSelect?: () => void;
    menuItems?: MenuItem[];
}

export interface HeaderMenuProps {
    id?: string;
    menuTitle: string;
    footer?: React.ReactNode;
    trigger: React.ReactNode;
    open: boolean;
    toggleMenu: (name?: string) => void;
}

const hide = keyframes({
    '0%': {
        opacity: 1,
    },
    '99%': {
        opacity: 1,
    },
    '100%': {
        opacity: 0,
        display: 'none',
    },
});

const show = keyframes({
    '0%': {
        opacity: 0,
        display: 'none',
    },
    '1%': {
        opacity: 0,
        display: 'none',
    },
    '100%': {
        opacity: 1,
        display: 'block',
    },
});

const styles = createStyles({
    mobileContainer: [
        'fixed bg-white w-full px-7 py-3 z-50 flex flex-col text-left',
        {
            top: 0,
            bottom: 0,
            right: 0,
            maxWidth: '20rem',
            boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1)',
            ...defaultEaseInOutBackAnimation,
        },
    ],
    desktopContainer: [
        'fixed bg-white shadow-xl px-7 py-6 border-solid border-1 rounded-lg border-brand w-full duration-900 w-full z-50 text-left',
        {
            minWidth: 325,
            right: 0,
            maxWidth: '20rem',
            top: 0,
        },
    ],
    mobileOverlay: [
        'fixed bg-brand/90 inset-0 z-40',
        {
            '&[data-state="open"]': { animation: `${fadeIn} 150ms ${EASE_OUT_QUART}` },
            '&[data-state="closed"]': { animation: `${fadeOut} 150ms ${EASE_OUT_QUART}` },
        },
    ],
    desktopOverlay: ['fixed left-0 h-screen w-screen z-40'],
});

export const NavMenu = ({
    menuTitle,
    trigger,
    footer,
    open,
    toggleMenu,
    children,
    id = '',
}: React.PropsWithChildren<HeaderMenuProps>) => {
    const [hasActivity, setHasActivity] = useState(false);
    const [top, setTop] = useState(-1);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    useMediaQueries({
        '(max-width: 640px)': () => {
            setIsMobile(true);
        },
        '(min-width: 640px)': () => {
            setIsMobile(false);
        },
    });

    const onChangeInternal = useCallback(() => {
        if (open) {
            toggleMenu('');
        } else {
            toggleMenu();
        }
    }, [toggleMenu, open]);

    useEffect(() => {
        if (!open) {
            // reset hasActivity to false when it is closed
            setHasActivity(false);
        }
    }, [open]);

    useEffect(() => {
        if (open && !hasActivity && !isMobile) {
            const t = setTimeout(() => toggleMenu(''), DEFAULT_AUTO_CLOSE_DURATION);
            return () => clearTimeout(t);
        }
    }, [open, hasActivity, toggleMenu, isMobile]);

    const updateTop = useCallback(() => {
        const relativeYElement = document.querySelector('#main-header');
        const newTop = Math.max(
            0,
            relativeYElement ? relativeYElement.getBoundingClientRect().bottom : -1
        );

        setTop(newTop);
    }, [setTop]);

    useEffect(() => updateTop(), [open, updateTop]);

    useEffect(() => {
        window.addEventListener('scroll', updateTop);
        return () => window.removeEventListener('scroll', updateTop);
    }, [updateTop]);

    const positionYCss = top ? `${top}px` : '0px';

    const desktopTransitionStyles = {
        transform: open ? `translateY(${positionYCss})` : 'translateY(-120%)',
        transitionProperty: 'transform',
        animation: open ? `${show} 0.5s forwards` : `${hide} 0.5s forwards`,
        ...defaultEaseInOutBackAnimation,
    };

    const desktopOverlayStyles = {
        display: open ? 'block' : 'none',
        top: positionYCss,
    };

    const mobileTransitionStyles = {
        transform: open ? 'translateX(0)' : 'translateX(100%)',
        transitionProperty: 'transform',
        animation: open ? `${show} 900ms forwards` : `${hide} 900ms forwards`,
        ...defaultEaseInOutBackAnimation,
    };

    const mobileOverlayStyles = {
        animation: open
            ? `${fadeIn} 150ms ${EASE_OUT_QUART}`
            : `${fadeOut} 150ms ${EASE_OUT_QUART}`,
    };

    return (
        <div className="relative">
            <ResponsiveContainer For={ContainerType.mobile}>
                {/* @ts-ignore */}
                <RuiDialog.Root onOpenChange={onChangeInternal} open={open}>
                    <RuiDialog.Trigger className="inline-block">{trigger}</RuiDialog.Trigger>
                    <div
                        style={mobileOverlayStyles}
                        className={classNames(styles.mobileOverlay, open ? 'block' : 'hidden')}
                        onClick={() => toggleMenu('')}
                    />
                    <div
                        className={`${id} ${styles.mobileContainer}`}
                        style={mobileTransitionStyles}
                    >
                        <div className="flex justify-between mb-5 bg-white items-center">
                            <header className="text-grey-1 font-semibold text-2xl">
                                {menuTitle}
                            </header>
                            <RuiDialog.Close className="flex pl-2 focus:outline-none">
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    className="text-brand text-2xl self-start"
                                />
                            </RuiDialog.Close>
                        </div>
                        <div>{children}</div>
                        {footer && (
                            <div className="flex flex-col flex-grow justify-end">
                                <footer className="mt-6">{footer}</footer>
                            </div>
                        )}
                    </div>
                </RuiDialog.Root>
            </ResponsiveContainer>
            <ResponsiveContainer For={ContainerType.desktop}>
                {/* @ts-ignore */}
                <RuiDialog.Root onOpenChange={onChangeInternal} open={open}>
                    <RuiDialog.Trigger className="inline-block">{trigger}</RuiDialog.Trigger>
                    <div
                        style={desktopOverlayStyles}
                        className={styles.desktopOverlay}
                        onClick={() => toggleMenu('')}
                    />
                    <div
                        style={desktopTransitionStyles}
                        onMouseOver={() => setHasActivity(true)}
                        onFocus={() => setHasActivity(true)}
                        className={`${id} ${styles.desktopContainer}`}
                    >
                        <div className="flex justify-between items-center mb-4">
                            <header className="text-grey-1 font-semibold text-2xl">
                                {menuTitle}
                            </header>
                            <RuiDialog.Close className="flex pl-2 focus:outline-none">
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    className="text-brand text-2xl self-start"
                                />
                            </RuiDialog.Close>
                        </div>
                        <div>{children}</div>
                        {footer && <footer className="mt-11">{footer}</footer>}
                    </div>
                </RuiDialog.Root>
            </ResponsiveContainer>
        </div>
    );
};
