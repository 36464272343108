import React from 'react';

import { Btn } from 'components/btn/Btn';
import { createStyles } from 'utils/createStyle';
import { ReactComponent as RaiseRightLogo } from '../../media/images/RaiseRightLogo.svg';

const styles = createStyles({
    masthead: 'shadow-lg flex justify-center pt-5 pb-3 sm:pt-6 sm:pb-4',
    logo: 'w-40 sm:w-60',
    imageBackground: 'max-w-6xl mx-auto pt-0 mb-2 bg-no-repeat bg-center bg-cover lg:bg-contain',
    contentContainer:
        'flex flex-col justify-center max-w-3xl mx-auto pb-20 md:pb-28 text-center px-6',
    title: [
        'text-brand text-8rem md:text-11rem',
        {
            background: 'linear-gradient(180deg, #F15A29 0%, #E6126A 100%)',
            backgroundClip: 'text',
            textFillColor: 'transparent',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            fontWeight: 700,
        },
    ],
    logoContainer: ['w-auto flex justify-center mb-10 mt-10'],
    logoSize: ['block', { width: '203px', height: '36px' }],
});

export const InvalidPaymentAccess = () => {
    let statusText = 'Sorry, an error occurred.  Let us help you get to the right place.';

    return (
        <main>
            <div className="lg:mt-14 md:mt-0 sm:mt-0 mt-0" />
            <div className={styles.logoContainer}>
                <a
                    href={process.env.REACT_APP_RAISE_RIGHT_APP_URL || 'https://www.raiseright.com'}
                    className={styles.logoSize}
                >
                    <RaiseRightLogo className="h-full" />
                </a>
            </div>
            <div>
                <div className={styles.contentContainer}>
                    <div className="flex flex-col text-3xl md:text-3xl text-brand-dark mt-4">
                        {statusText}
                        <div className="flex flex-col md:flex-row gap-4 md:gap-8 justify-center mt-10 md:mt-20">
                            <div>
                                <a
                                    href={
                                        process.env.REACT_APP_RAISE_RIGHT_APP_URL ||
                                        'https://www.raiseright.com'
                                    }
                                >
                                    <Btn>RaiseRight Home</Btn>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};
