import { getRemoteConfig, fetchAndActivate, getValue } from 'firebase/remote-config';
import { isSupported } from 'firebase/analytics';

import { defaultConfigKeys } from 'common/modules/remote-config/defaultConfigKeys';
import { MINUTE } from 'common/utils/time';
import { isPaymentHost } from 'utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withNRetries = <F extends (...args: any[]) => Promise<any>>(
    fn: F,
    retries: number
) => {
    let tries = 0;
    const retry = async (...args: Parameters<F>): Promise<ReturnType<F>> => {
        try {
            const result = await fn(...args);
            return result;
        } catch (e) {
            ++tries;
            if (tries < retries) {
                await new Promise((resolve) => setTimeout(resolve, 2 ** tries * 100));
                return retry(...args);
            }
            return Promise.reject(e);
        }
    };
    return retry;
};

export const RemoteConfigWeb = (() => {
    let initializePromise: Promise<void> | undefined;

    const _initialize = async () => {
        const canActivateRemoteConfig = !isPaymentHost();
        const supported = canActivateRemoteConfig && (await isSupported());

        if (supported) {
            getRemoteConfig().settings.minimumFetchIntervalMillis = 5 * MINUTE;
            getRemoteConfig().defaultConfig = defaultConfigKeys;

            await withNRetries(async () => {
                await fetchAndActivate(getRemoteConfig());
            }, 4)();
        }
    };

    return {
        initialize: async () => {
            try {
                if (initializePromise) {
                    return await initializePromise;
                }

                initializePromise = _initialize();
                return await initializePromise;
            } catch (e) {
                console.error(e);
            } finally {
                initializePromise = undefined;
            }
        },
        refresh: async () => {
            try {
                return await withNRetries(async () => {
                    await fetchAndActivate(getRemoteConfig());
                }, 4)();
            } catch (e) {
                console.error(e);
            }
        },
        getRemoteValue: (key: keyof typeof defaultConfigKeys) => getValue(getRemoteConfig(), key),
    };
})();

export default RemoteConfigWeb;
