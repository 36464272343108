import React from 'react';
import { Store } from 'redux';

import { ErrorFallback } from './ErrorFallback';

import { ErrorLogger } from 'modules/error-logger/ErrorLogger';
import { RootState } from 'rootStore';
import { noop } from 'common/utils';
import { IApplicationInsights } from '@microsoft/applicationinsights-web';

interface ErrorBoundaryProps {
    children: React.ReactNode;
    store: Store<RootState>;
    appInsights?: IApplicationInsights;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
    state: { error: Error | undefined } = {
        error: undefined,
    };

    unsubscribe = noop;
    userId: string | null = null;

    // Ideally we'd use App Insight's enableUnhandledPromiseRejectionTracking config property,
    // but it's pretty useless. It just logs that an unhandled rejection happened. It doesn't
    // provide any info about where it originated or differentiate one kind of rejection from another.
    onUnhandledRejection = (e: PromiseRejectionEvent) => {
        const error = e.reason instanceof Error ? e.reason : new Error(e.reason);
        const { appInsights } = this.props;
        if (appInsights) {
            ErrorLogger.logError(error, appInsights);
        }
    };

    onStoreChange = () => {
        const { userId } = this.props.store.getState().store.account;
        if (userId !== this.userId) {
            this.userId = userId;
            const { appInsights } = this.props;
            if (appInsights) {
                ErrorLogger.setUserContext(userId, appInsights);
            }
        }
    };

    componentDidMount() {
        window.addEventListener('unhandledrejection', this.onUnhandledRejection);
        this.unsubscribe = this.props.store.subscribe(this.onStoreChange);
        this.onStoreChange();
    }

    componentWillUnmount() {
        window.removeEventListener('unhandledrejection', this.onUnhandledRejection);
        this.unsubscribe();
    }

    componentDidCatch(error: Error) {
        this.setState({ error });
        const { appInsights } = this.props;
        if (appInsights) {
            ErrorLogger.logError(error, appInsights);
        }
    }

    render() {
        const { error } = this.state;

        if (!error) {
            return this.props.children;
        }

        return <ErrorFallback />;
    }
}
