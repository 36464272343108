import React, { Suspense, useState, useEffect, useMemo } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Store } from 'redux';
import { Provider, useSelector } from 'react-redux';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';

import 'focus-visible';

import './index.css';
import './styles/global';
import { Routes } from 'routes';
import { AlertOutput, AlertProvider } from 'modules/alerts';
import { createRootStore, RootState } from 'rootStore';
import { LoadingScreen } from 'components/loading-screen/LoadingScreen';
import { ErrorBoundary } from 'components/error-boundary/ErrorBoundary';
import { InternalRoute } from 'components/internal-route/InternalRoute';
import { AppInit, PaymentAppInit } from 'components/app-init';
import { NotFoundScreen } from 'features/common/NotFoundScreen';
import { Head } from 'components/head/Head';
import { initFirebase } from 'config/firebase';
import { Enrollments } from 'features/coordinator/Enrollments';
import { FamilyEnrollment } from 'features/family-enrollment/FamilyEnrollment';
import { useAuthHook, UserAuthBff } from 'features/auth/components/UserAuthBff';
import { NavMenuProvider } from 'components/nav/context';
import {
    LoginWhenSessionExpires,
    useBroadcastChannel,
} from './features/auth/components/LoginWhenSesssionExpires';
import ZenDeskWidget from 'components/zendesk';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
    AppInsightsContext,
    ReactPlugin,
    withAITracking,
} from '@microsoft/applicationinsights-react-js';
import { CategoryScreen } from 'features/landing-pages/category/CategoryScreen';
import { MetadataPage } from 'components/metadata/constants';
import { Metadata } from 'components/metadata';
import { patchFetch } from 'utils/testing';
// import { isShipToCoordinatorEnabledHandler } from 'common/mocks/handlers/crmApi';
import { CHECKOUT_JS_URL, THREEDS_BASE_URL } from 'config';
import { OrgEnrollmentV2 } from './features/coordinator/enrollment-flow/v2/OrgEnrollment2';
import { FeatureFlag } from 'components/feature-flag';
import { getAccount } from 'common/api/users/service';
import { CoordinatorDashboard } from 'features/new-coordinator-dashboard/CoordinatorDashboard';
import { ConstructorPersonalizationWatcher } from 'third-party-integrations/constructor/ConstructorPersonalization';
import { getProceduralFeatureFlags } from 'common/hooks/useFeatureFlags';
import { RoktLauncherContextProvider } from 'third-party-integrations/rokt';
import { useSetupWindowListeners } from 'hooks/useSetupWindowListeners';
import { IterableInBrowserMessaging } from 'third-party-integrations/iterable-in-browser-messaging';
import { isInIframe, isPaymentHost } from 'utils';
import { useHomeDetailsFromBff } from 'common/features/store/duck/home/utils/useHomeDetailsFromBff';
import { InvalidPaymentAccess } from 'features/common/InvalidPaymentAccess';

const reactPlugin = new ReactPlugin();

var appInsights: ApplicationInsights | undefined;

const canInitAnalytics = !isPaymentHost();

if (canInitAnalytics) {
    appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: process.env.REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY,
            enableAutoRouteTracking: true,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            extensions: [reactPlugin as any],
            disableFetchTracking: false,
            enableCorsCorrelation: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            correlationHeaderDomains: ['*.raiseright.com'],
        },
    });
    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer((envelope) => {
        if (envelope && envelope.tags) {
            envelope.tags[
                'ai.cloud.role'
            ] = `${process.env.REACT_APP_RUNTIME_ENVIRONMENT}.raiseright.web`;
        }
    });
}
const StyleGuideScreen = React.lazy(() => import('style-guide/StyleGuideScreen'));
const DeepLinkGenerator = React.lazy(
    () => import('features/tools/deep-link-generator/DeepLinkGenerator')
);
const ShopRoutes = React.lazy(() => import('features/shop-base/ShopRoutes'));

if (canInitAnalytics) {
    if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
        const tagManagerArgs: TagManagerArgs = {
            gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
        };
        TagManager.initialize(tagManagerArgs);
    } else {
        console.warn('GoogleTagManager ID was not specified.');
    }
}

if (canInitAnalytics && process.env.REACT_APP_FIREBASE_API_KEY) {
    initFirebase();
}

const runtimeEnv = process.env.REACT_APP_RUNTIME_ENVIRONMENT;
const isStageOrProd = runtimeEnv === 'stage' || runtimeEnv === 'prod';

const AutoClosesWindowIfOpenerStillAlive = () => {
    const broadcastChannel = useBroadcastChannel();

    useEffect(() => {
        const handleCloseConfirmation = (e: MessageEvent) => {
            const { message } = e.data;
            if (message === 'login-success-close-window') {
                window.close();
            }
        };

        broadcastChannel.addEventListener('message', handleCloseConfirmation);

        return () => broadcastChannel.removeEventListener('message', handleCloseConfirmation);
    }, [broadcastChannel]);

    useEffect(() => {
        (async () => {
            const tabId = parseInt(window.location.search.replace(/[^0-9]/gi, ''));
            const { email = '' } = (await getAccount()).data || {};
            broadcastChannel.postMessage({
                message: 'login-success',
                payload: {
                    email,
                    tabId,
                },
            });
        })();

        const timeout = setTimeout(() => {
            window.location.assign(Routes.Shop);
        }, 2000);

        return () => clearTimeout(timeout);
    }, [broadcastChannel]);

    return <div>Sign in...</div>;
};

const PaymentUserAuthFromBff = ({ children }: { children: React.ReactNode }) => {
    useHomeDetailsFromBff();
    return <>{children}</>;
};

function App() {
    useSetupWindowListeners();

    // HACK: Temporary patch to simulate the Product Brand Card Images API
    if (location.hostname === 'localhost') {
        patchFetch();
    }

    useEffect(() => {
        if (isInIframe()) {
            // hide scrollbars in iframe. overrides the default style of the page
            document.documentElement.style.overflowY = 'hidden';
            document.body.style.overflowY = 'hidden';
        }
    }, []);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).CHECKOUT_JS_URL = CHECKOUT_JS_URL;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).THREEDS_BASE_URL = THREEDS_BASE_URL;
    }, []);

    const [store, setStore] = useState<Store<RootState>>();
    useEffect(() => {
        createRootStore().then(setStore);
    }, []);

    useEffect(() => {
        if (!store) {
            return;
        }

        store?.subscribe(() => {
            const state = store.getState();
            const rootState = state as RootState;
            const userLoggedIn = rootState?.store?.account?.userId;
            getProceduralFeatureFlags().setUserIsLoggedIn(Boolean(userLoggedIn));
        });
    }, [store]);

    const startProgramRoutes = useMemo(
        () => Object.values(Routes).filter((r) => r.startsWith(Routes.StartProgram)),
        []
    );
    const enrollRoutes = useMemo(
        () =>
            Object.values(Routes).filter(
                (r) =>
                    r.startsWith(Routes.Enroll) &&
                    r !== Routes.Enroll &&
                    !r.startsWith(Routes.EnrollFamily)
            ),
        []
    );
    const shopRoutes = useMemo(
        () => [
            Routes.Brands,
            Routes.BrandsDetails,
            Routes.BrandsGiftCardDetails,
            Routes.BrandsShopOnlineDetails,
            Routes.BrandBulkOrder,
            Routes.ShopLocalProductDetails,
            Routes.ShopGiftCards,
            ...Object.values(Routes).filter((r) => r.startsWith(Routes.Shop)),
        ],
        []
    );
    const familyRoutes = useMemo(
        () =>
            Object.values(Routes).filter(
                (r) =>
                    (r == Routes.Enroll || r.startsWith(Routes.EnrollFamily)) &&
                    r !== Routes.EnrollFamily
            ),
        []
    );
    const orgEnrollmentV2 = useMemo(
        () =>
            Object.values(Routes).filter(
                (r) => r == Routes.EnrollV2 || r.startsWith(Routes.EnrollV2)
            ),
        []
    );

    const landingRoutes = [Routes.OnBonus, Routes.Featured, Routes.Favorites];

    const coordinatorDashboardRoutes = [
        Routes.CoordinatorDashboardRoot,
        Routes.CoordinatorDashboardHome,
    ];

    if (!store) {
        return null;
    }

    const renderRoutes = () => (
        <Switch>
            <Route path={'/close-window'}>
                <AutoClosesWindowIfOpenerStillAlive />
            </Route>
            <Route path={[...coordinatorDashboardRoutes]}>
                <CoordinatorDashboard />
            </Route>
            <Route path={Routes.Home} exact>
                <Redirect to="/shop" />
            </Route>
            <Route path={[...orgEnrollmentV2]} exact>
                <Metadata page={MetadataPage['enroll/start-program']}>
                    <FeatureFlag flagKey="enrollment_v2_enabled" displayOnValue={true}>
                        <OrgEnrollmentV2 />
                    </FeatureFlag>
                    <FeatureFlag flagKey="enrollment_v2_enabled" displayOnValue={false}>
                        <NotFoundScreen />
                    </FeatureFlag>
                </Metadata>
            </Route>
            <Route path={[...startProgramRoutes, ...enrollRoutes]}>
                <Metadata page={MetadataPage['enroll/start-program']}>
                    <Enrollments />
                </Metadata>
            </Route>
            <Route path={[...familyRoutes]} exact>
                <Metadata page={MetadataPage['enroll/join-program']}>
                    <FamilyEnrollment />
                </Metadata>
            </Route>
            <Route path={[Routes.ShopByCategory]}>
                <Metadata page={MetadataPage.category}>
                    <CategoryScreen />
                </Metadata>
            </Route>
            <Route path={[...shopRoutes, ...landingRoutes]} exact>
                <ShopRoutes />
            </Route>
            <InternalRoute path={Routes.StyleGuide}>
                <StyleGuideScreen />
            </InternalRoute>
            <InternalRoute path={Routes.DeepLinkGenerator}>
                <DeepLinkGenerator />
            </InternalRoute>
            <Route>
                <NotFoundScreen />
            </Route>
        </Switch>
    );

    if (isInIframe()) {
        return (
            <HelmetProvider>
                <ErrorBoundary store={store}>
                    <BrowserRouter>
                        {/* <Head title={document.title}>
                                {!isStageOrProd && <script src="/scripts/site24x7.js"> </script>}
                            </Head> */}
                        <Provider store={store}>
                            <AlertProvider>
                                <Suspense fallback={<LoadingScreen />}>
                                    <PaymentUserAuthFromBff>
                                        <PaymentAppInit>{renderRoutes()}</PaymentAppInit>
                                    </PaymentUserAuthFromBff>
                                </Suspense>
                                <AlertOutput />
                            </AlertProvider>
                        </Provider>
                    </BrowserRouter>
                </ErrorBoundary>
            </HelmetProvider>
        );
    } else if (
        window.location.host.startsWith('payment') ||
        window.location.host.endsWith(':5003')
    ) {
        //if someone tries to direct access the payment website outside of the iframe then show an error that directs them to the main site
        return (
            <HelmetProvider>
                <Metadata page={MetadataPage.invalidAccess}>
                    <InvalidPaymentAccess />
                </Metadata>
            </HelmetProvider>
        );
    }

    return (
        <HelmetProvider>
            <ErrorBoundary store={store} appInsights={appInsights}>
                <AppInsightsContext.Provider value={reactPlugin}>
                    <BrowserRouter>
                        <Head title={document.title}>
                            {!isStageOrProd && <script src="/scripts/site24x7.js"> </script>}
                        </Head>
                        <Provider store={store}>
                            <RoktLauncherContextProvider
                                accountId={process.env.REACT_APP_ROKT_ACCOUNT_ID}
                                sandbox={process.env.REACT_APP_RUNTIME_ENVIRONMENT !== 'prod'}
                            >
                                <AlertProvider>
                                    <Suspense fallback={<LoadingScreen />}>
                                        <UserAuthBff>
                                            <AppInit>
                                                <LoginWhenSessionExpires>
                                                    <ZenDeskWidget>
                                                        <Metadata page={MetadataPage.default}>
                                                            <NavMenuProvider>
                                                                {renderRoutes()}
                                                            </NavMenuProvider>

                                                            <ConstructorPersonalizationWatcher />

                                                            <IterableInBrowserMessaging />
                                                        </Metadata>
                                                    </ZenDeskWidget>
                                                </LoginWhenSessionExpires>
                                            </AppInit>
                                        </UserAuthBff>
                                    </Suspense>
                                    <AlertOutput />
                                </AlertProvider>
                            </RoktLauncherContextProvider>
                        </Provider>
                    </BrowserRouter>
                </AppInsightsContext.Provider>
            </ErrorBoundary>
        </HelmetProvider>
    );
}

export default withAITracking(reactPlugin, App);
