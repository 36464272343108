import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Progress from '@radix-ui/react-progress';

import { SearchGrid } from '../../../components/search-grid/SearchGrid';

import { TopPicksCard } from './TopPicksCard';
import { CardContext } from './contexts';
import { useSearchGridStyles } from './hooks';

import { brandSearch } from 'common/features/store/duck/search-generic/duck';
import { FeaturesState } from 'common/features/featuresReducer';
import { useRootSelector } from 'rootStore';
import { createStyles } from 'utils/createStyle';
import { EarnTypeConstructorFilter } from 'common/api/constructor/util';
import { firebaseValueAsString, useFirebaseConfigValues } from 'hooks/useFirebaseConfigValues';

const styles = createStyles({
    totalContainer: ['flex flex-col items-center mb-4'],
    totalText: ['text-grey-1', { fontSize: 14, lineHeight: '22px' }],
    root: ['rounded-3xl bg-grey-5 h-2'],
    indicator: ['rounded-3xl bg-bonus-pink h-2'],
    loadMore: [
        'py-2 px-10 rounded-3xl bg-brand text-white font-semibold',
        { fontSize: 18, lineHeight: '20px' },
    ],
    containerStyles: ['mt-8 sm:mt-0 mb-12 mx-4 lg:mx-16 2xl:mx-28', { marginBottom: 50 }],
    affiliateErrorContainer: ['flex items-center justify-center mt-20 mb-20'],
});

export const LoadMoreSection = () => {
    const { navigatePage } = brandSearch;
    const { to, total, loading } = useRootSelector((s: FeaturesState) => s.store.searchGrid);
    const dispatch = useDispatch<any>();

    return !loading && total ? (
        <div className="mt-8 text-center">
            <div className={styles.totalContainer}>
                <p className={styles.totalText}>
                    Viewing <span className="font-bold text-brand">{to}</span> of{' '}
                    <span className="font-bold text-brand">{total}</span> Results
                </p>
                <Progress.Root
                    className={styles.root}
                    style={{ width: 180 }}
                    value={to}
                    max={total}
                >
                    <Progress.Indicator
                        className={styles.indicator}
                        style={{ width: `${(to / total) * 100}%` }}
                    />
                </Progress.Root>
            </div>
            {to < total && (
                <button className={styles.loadMore} onClick={() => dispatch(navigatePage('next'))}>
                    Load More Items
                </button>
            )}
        </div>
    ) : null;
};

interface TopPicksSectionProps {
    earnType?: EarnTypeConstructorFilter;
    singleTypeView?: boolean;
}

const TopPicksSection = ({ earnType, singleTypeView }: TopPicksSectionProps) => {
    const affiliateErrorMessage = useFirebaseConfigValues(
        'affiliate_error_message_web',
        null,
        firebaseValueAsString
    );

    const { pageBrands, loading, hasFetched } = useSelector(
        (state: FeaturesState) => state.store.searchGrid
    );
    const [, width] = useSearchGridStyles<'150', '180'>('150', '180');

    if (affiliateErrorMessage && earnType === 'Online' && singleTypeView) {
        return <div className={styles.affiliateErrorContainer}>{affiliateErrorMessage}</div>;
    }

    return (
        <section id="toppicks" className={styles.containerStyles}>
            <CardContext.Provider value={{ width }}>
                <SearchGrid
                    ItemComponent={TopPicksCard}
                    items={pageBrands}
                    isLoading={!hasFetched || loading}
                    earnType={earnType}
                    singleTypeView={singleTypeView}
                />
            </CardContext.Provider>
            <LoadMoreSection />
        </section>
    );
};

export default TopPicksSection;
