// @ts-ignore
import jwtDecode from 'jwt-decode';

import { AuthResponse } from './users/models/AuthResponse';

import { persistentStorage } from 'common/utils/persistentStorage';

const STORAGE_KEY = '__TOKENS__';

const VERSION = '1.0.0'; // Increment whenever TokenManagerValue's shape changes
export type TokenManagerValue = {
    user: AuthResponse;
    tokens: { [key: string]: AuthResponse };
};

export interface ParsedJwtToken {
    nameid: string;
}

const createTokenManager = () => {
    const emptyValue: TokenManagerValue = {
        user: {
            token: '',
            refreshToken: '',
            inactivityTimeoutMinutes: null,
        },
        tokens: {},
    };
    const storage = persistentStorage<TokenManagerValue>(
        STORAGE_KEY,
        VERSION,
        emptyValue,
        JSON.parse
    );

    return {
        getTokens: storage.get,
        setTokens: async (tokens: AuthResponse) => {
            const value = await storage.get();
            const decoded = jwtDecode<ParsedJwtToken>(tokens.token);

            return storage.set({
                user: tokens,
                tokens: {
                    ...value.tokens,
                    [`${decoded.nameid}`]: tokens,
                },
            });
        },
        listen: storage.listen,
    };
};

export const tokenManager = createTokenManager();
