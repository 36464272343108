import { useEffect, useState } from 'react';
import { Value } from 'firebase/remote-config';

import { defaultConfigKeys } from 'common/modules/remote-config/defaultConfigKeys';
import { RemoteConfigWeb } from 'config/RemoteConfigWeb';
import { isInIframe } from 'utils';

export const useFirebaseConfigValues = <T>(
    key: keyof typeof defaultConfigKeys,
    fallback: T,
    // NOTE: Do not inline the function otherwise effect will run many times
    transform: (value: Value) => T | undefined
) => {
    const [value, setValue] = useState<T | undefined>(fallback);

    useEffect(() => {
        if (isInIframe()) {
            return;
        }
        const remoteConfigCall = async () => {
            await RemoteConfigWeb.initialize();
            const keyValue = transform(RemoteConfigWeb.getRemoteValue(key));
            setValue(keyValue);
        };
        remoteConfigCall();
    }, [key, transform]);

    if (isInIframe()) {
        return fallback;
    }
    return value;
};

export const firebaseValueAsNumber = (value: Value) => value.asNumber();
export const firebaseValueAsBoolean = (value: Value) => value.asBoolean();
export const firebaseValueAsString = (value: Value) => value.asString();
