import { Dispatch } from 'redux';
import keyBy from 'lodash.keyby';
import { createSelector } from 'reselect';
import { batchActions } from 'redux-batched-actions';

import * as FavoriteService from 'common/api/e-comm/brands/favorite';
import {
    BrandCatalogSources,
    type FavoriteId,
} from 'common/api/e-comm/models/FavoriteBrandDataModel';
import { createSlice } from 'common/modules/create-slice';
import { removeFirst, isDefined } from 'common/utils';
import { ECommBrand } from 'common/api/e-comm/models/ECommBrand';
import {
    AsyncActionState,
    createApiThunk,
    initialAsyncActionState,
    createThunk,
} from 'common/modules/async-actions/thunk';
import { getBrand, getRelatedBrands, getBrands } from 'common/api/e-comm/brands';
import { PartialRecord } from 'common/definitions/PartialRecord';
import { ContentfulBrand } from 'common/api/contentful/models/ContentfulBrand';
import { getContentfulBrand } from 'common/api/contentful/brands';
import { FeaturesState } from 'common/features/featuresReducer';
import { Product } from 'common/api/e-comm/models/Product';

export type BrandsById = PartialRecord<number, ECommBrand>;
export type ReloadProductsById = Partial<{ [brandId: number]: Product }>;

export interface BrandsState {
    brandsById: BrandsById;
    relatedBrandsById: PartialRecord<number, number[]>;
    favorites: FavoriteId[];
    favoritesAsyncState: AsyncActionState;
    contentfulBrandsById: PartialRecord<number, ContentfulBrand>;
    reloadProductsById: ReloadProductsById;
    favoritesToAdd: string[];
}

const initialFavoritesState: Pick<
    BrandsState,
    'favorites' | 'favoritesAsyncState' | 'favoritesToAdd'
> = {
    favorites: [],
    favoritesAsyncState: initialAsyncActionState,
    favoritesToAdd: [],
};

export const initialBrandState: BrandsState = {
    brandsById: {},
    relatedBrandsById: {},
    contentfulBrandsById: {},
    reloadProductsById: {},
    ...initialFavoritesState,
};

const { reducer, update, configureAction } = createSlice(initialBrandState, 'BRANDS');
export const brandsReducer = reducer;

export const upsertBrand = configureAction<ECommBrand>('UPSERT_BRAND', (brand) => (s) => ({
    ...s,
    brandsById: { ...s.brandsById, [brand.id]: brand },
}));

export const upsertBrands = configureAction<{ [id: string]: ECommBrand }>(
    'UPSERT_BRANDS',
    (brands) => (s) => ({ ...s, brandsById: { ...s.brandsById, ...brands } })
);

export const upsertReloadProducts = configureAction<{ [brandId: number]: Product }>(
    'UPSERT_RELOAD_PRODUCT',
    (reloadProducts) => (s) => ({
        ...s,
        reloadProductsById: { ...s.reloadProductsById, ...reloadProducts },
    })
);

const upsertRelatedBrands = configureAction<{ brandId: number; relatedBrands: ECommBrand[] }>(
    'UPSERT_RELATED_BRANDS',
    ({ brandId, relatedBrands }) =>
        (s) => {
            const brandsById = { ...s.brandsById, ...keyBy(relatedBrands, (b) => b.id) };
            const relatedBrandsById = {
                ...s.relatedBrandsById,
                [brandId]: relatedBrands.map((b) => b.id),
            };
            return { ...s, brandsById, relatedBrandsById };
        }
);

const upsertContentfulBrand = configureAction<ContentfulBrand>(
    'UPSERT_CONTENTFUL_BRAND',
    (brand) => (s) => ({
        ...s,
        contentfulBrandsById: { ...s.contentfulBrandsById, [brand.brandId]: brand },
    })
);

const addFavorite = configureAction<FavoriteId>('ADD_FAVORITE', (brandId) => (s) => ({
    ...s,
    favorites: [...(s.favorites || []), brandId],
}));

const removeFavorite = configureAction<FavoriteId>('REMOVE_FAVORITE', (brandId) => (s) => ({
    ...s,
    favorites: removeFirst(s.favorites || [], brandId),
}));

const toggleBrandFavoritesToAddList = configureAction<string>(
    'TOGGLE_BRAND_FAVORITES_TO_ADD_LIST',
    (brandId) => (s) => ({
        ...s,
        favoritesToAdd: s.favoritesToAdd.includes(brandId)
            ? removeFirst(s.favoritesToAdd || [], brandId)
            : [...(s.favoritesToAdd || []), brandId],
    })
);

const resetBrandFavoritesToAddList = configureAction<void>(
    'RESET_BRAND_FAVORITES_TO_ADD_LIST',
    () => (s) => ({
        ...s,
        favoritesToAdd: [],
    })
);

export const getBrandsThunk = createApiThunk(getBrands, () => (_asyncState, result) => {
    return result ? upsertBrands(keyBy(result, (brand) => brand.id)) : undefined;
});

export const getBrandThunk = createApiThunk(getBrand, () => (_asyncState, result) => {
    return result ? upsertBrand(result) : undefined;
});

export const getRelatedBrandsThunk = createApiThunk(
    getRelatedBrands,
    (brandId) => (_asyncState, relatedBrands) => {
        return relatedBrands ? upsertRelatedBrands({ brandId, relatedBrands }) : undefined;
    }
);

export const getContentfulBrandThunk = createThunk(
    getContentfulBrand,
    () => (_asyncState, brand) => {
        return brand ? upsertContentfulBrand(brand) : undefined;
    }
);

export const getFavoritesThunk = createApiThunk(
    FavoriteService.getFavorites,
    () => (favoritesAsyncState, favorites) => {
        return favorites
            ? update({
                  favoritesAsyncState,
                  favorites: favorites.map(FavoriteService.parseFavoriteId),
              })
            : update({ favoritesAsyncState });
    },
    { joinParallelCalls: true }
);

export const favoriteBrandThunk = (brandId: FavoriteId) => (dispatch: Dispatch) => {
    dispatch(addFavorite(brandId));
    const source = FavoriteService.getBrandCatalogSourceFromId(brandId);

    if (source !== '') {
        const rawId =
            source === BrandCatalogSources.RaiseRight ? brandId : `${brandId}`.split('-')[1];
        return FavoriteService.favoriteBrand(rawId, source);
    }

    return Promise.reject('Unable to  parse Brand Id Source');
};

export const unfavoriteBrandThunk = (brandId: FavoriteId) => (dispatch: Dispatch) => {
    dispatch(removeFavorite(brandId));
    const source = FavoriteService.getBrandCatalogSourceFromId(brandId);

    if (source !== '') {
        const rawId =
            source === BrandCatalogSources.RaiseRight ? brandId : `${brandId}`.split('-')[1];
        return FavoriteService.unfavoriteBrand(rawId, source);
    }

    return Promise.reject('Unable to  parse Brand Id Source');
};

export const toggleFavoriteToAddThunk = (brandId: string) => (dispatch: Dispatch) => {
    return dispatch(toggleBrandFavoritesToAddList(brandId));
};

export const resetFavoriteToAddThunk = () => (dispatch: Dispatch) => {
    return dispatch(resetBrandFavoritesToAddList());
};

export const selectRelatedBrands = createSelector(
    (brandId: number) => brandId,
    (brandId) =>
        createSelector(
            (state: BrandsState) => state.brandsById,
            (state: BrandsState) => state.relatedBrandsById,
            (brandsById, relatedBrandsById) => {
                const relatedBrandIds = relatedBrandsById[brandId];
                if (relatedBrandIds) {
                    const relatedBrands = relatedBrandIds
                        .map((id) => brandsById[id])
                        .filter(isDefined);
                    return relatedBrands;
                }
            }
        )
);

export const resetFavorites = () => update(initialFavoritesState);
export const resetContentfulBrands = () => update({ contentfulBrandsById: {} });
export const resetRelatedBrands = () => update({ relatedBrandsById: {} });
export const selectBrandId = (state: FeaturesState) => state.store.brands.brandsById;
export const selectReloadProducts = (state: FeaturesState) => state.store.brands.reloadProductsById;
export const resetBrands = () =>
    batchActions([resetFavorites(), resetContentfulBrands(), resetRelatedBrands()]);
export const selectFavorites = (state: FeaturesState) => state.store.brands.favorites;
export const selectFavoritesToAdd = (state: FeaturesState) => state.store.brands.favoritesToAdd;
export const selectIsFavorited = () =>
    createSelector(
        (state: FeaturesState, id: string) =>
            state.store?.brands?.favoritesToAdd?.includes(id) || false,
        (included: boolean) => included
    );
