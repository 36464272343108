import React, { useCallback, useRef, useEffect, useContext } from 'react';
import classNames from 'classnames';
import { Entry } from 'contentful';
import { useHistory, useParams } from 'react-router-dom';
import { PlaidLinkOnExit } from 'react-plaid-link';

import { PlaidWrapper } from '../../enrollment/steps/PlaidButton/PlaidButton';
import { EnrollmentContext } from '../context';

import schoolBus from 'media/images/school-bus.jpg';
import { createStyles } from 'utils/createStyle';
import { TiledButton } from 'components/tiled-button/TiledButton';
import { Btn } from 'components/btn/Btn';
import { colors } from 'styles/settings';
import { TiledButton as TypeTiledButton } from 'common/api/contentful/models/OrganizationEnrollment';
import { getPath, Routes } from 'routes';
import { deleteOrgAchAccount } from 'common/api/e-comm/ach';
import { PlaidErrorCode } from 'common/utils/plaidVerification';
import { LoadingOverlay, openLoader, closeLoader } from 'components/loading-overlay/LoadingOverlay';
import { useHomeDetails } from 'common/features/store/duck/home/utils/useHomeDetails';
import { useRootSelector } from 'rootStore';
import { EmailCheckSection } from 'features/coordinator/components/EmailCheckSection';

interface OrgEnrollCompletedProps {
    curatedArticles: Entry<TypeTiledButton>[];
    status?: string;
}

export interface OrgEnrollCompletedParams {
    status?: '' | 'paper-only';
}

const styles = createStyles({
    wrapper: 'flex flex-col p-0 mx-0 max-w-full sm:mx-auto sm:max-w-screen-xl mt-0',
    subHeader: 'relative pt-0 pb-3 font-light sm:flex sm:pt-0 sm:pb-4',
    titleInfo:
        'w-full h-80 sm:h-96 z-99 absolute sm:relative sm:bg-brand-accent/90 sm:w-3/5 flex flex-col word-wrap justify-center align-start bg-brand-accent/80 text-3xl text-white px-8 lg:pl-20 ',
    image: 'w-full h-80 sm:h-96 bg-no-repeat bg-center bg-cover  absolute sm:relative sm:w-2/5 ',
    subTitle: 'text-3xl sm:text-5xl text-brand-dark font-light text-left',
    icon: 'mt-0 mb-0 sm:mt-6 sm:mb-4 sm:px-0 px-2 text-brand h-10 sm:h-16',
    contentWrapper:
        'max-w-full relative mt-80 sm:mt-0 sm:max-w-3xl mx-auto px-8 text-lg text-grey-1 lg:px-0',
    button: [
        'sm:flex-col w-full mb-8 sm:mb-0 text-left sm:text-center',
        {
            borderColor: colors.grey5,
            '@media screen and (max-width: 640px)': {
                justifyContent: 'flex-end',
                flexDirection: 'row-reverse',
                padding: 0,
                height: 70,
            },
        },
    ],
});

interface BaseViewProps extends Pick<OrgEnrollCompletedProps, 'curatedArticles'> {
    titleInfo: React.ReactNode;
    nextSteps: React.ReactNode;
}

const BaseView = ({ curatedArticles, titleInfo, nextSteps }: BaseViewProps) => {
    return (
        <div className={styles.wrapper}>
            <div className={classNames(styles.subHeader, status && '')}>
                <div
                    style={{
                        backgroundImage: `linear-gradient(#00000090, #00000045), url(${schoolBus})`,
                    }}
                    className={styles.image}
                />
                <div className={styles.titleInfo}>{titleInfo}</div>
            </div>

            <div className={styles.contentWrapper}>
                <div className="mb-6 sm:mb-10" />
                <h1 tabIndex={0} className={styles.subTitle}>
                    What's next?
                </h1>
                <br />
                {nextSteps}
                <div className="mb-6 sm:mb-16" />
                <h2 className={styles.subTitle}>Want to get a head start?</h2>
                <br />
                <p>
                    These curated articles will help you learn about running a successful
                    fundraising program. We recommend reading them so you can hit the ground running
                    once you are approved.
                </p>
                <span className="flex mt-8 mb-2 sm:my-12 flex-col sm:flex-row">
                    {curatedArticles.map(({ fields }) => {
                        const { buttonLink, image } = fields;

                        return (
                            <TiledButton
                                key={buttonLink.fields.title}
                                buttonClassName={styles.button}
                                buttonText={buttonLink.fields.title}
                                onClick={() => window.open(buttonLink.fields.url, '_blank')}
                                icon={
                                    <img
                                        alt=""
                                        className={styles.icon}
                                        src={image.fields.file.url}
                                    />
                                }
                            />
                        );
                    })}
                </span>
                <p>
                    For additional resources check out our{' '}
                    <a href="https://www.raiseright.com/resources/">support materials</a> and our{' '}
                    <a href="https://www.raiseright.com/blog/">blog</a>.
                </p>
                <br />
                <p>
                    If you have questions while we approve your organization, our{' '}
                    <a href="https://www.raiseright.com/about-us/contact/">Customer Support</a> team
                    is happy to help.
                </p>
            </div>
            <div className="mb-6 sm:mb-16" />
        </div>
    );
};

const PaperOnlyView = ({ curatedArticles }: OrgEnrollCompletedProps) => {
    const home = useRootSelector((s) => s.store.home);
    const firstName = home.homeDetails?.profile?.firstName;
    const ref = useRef<LoadingOverlay>(null);
    useHomeDetails();

    useEffect(() => {
        if (home.homeDetailsAsyncState.loading) {
            openLoader(ref.current);
        } else {
            setTimeout(() => {
                closeLoader(ref.current);
            }, 500);
        }
    }, [home.homeDetailsAsyncState.loading]);

    const TitleInfo = (
        <div>
            <span className="text-4xl lg:pr-64 font-light">
                You're on your way{firstName ? ` ${firstName}` : ''}.
            </span>
            <LoadingOverlay ref={ref} />
            <br />
            <h3 className="font-light sm:font-bold mt-8">Now it’s on us.</h3>
        </div>
    );

    const WhatsNext = (
        <p>
            Paper Only programs are setup through New Customer Support. We will review your
            information to confirm your organization's eligibility. When we contact you, our New
            Customer Support team will help you complete your enrollment.
        </p>
    );

    return (
        <BaseView titleInfo={TitleInfo} nextSteps={WhatsNext} curatedArticles={curatedArticles} />
    );
};

const DepositsView = ({ curatedArticles, status }: OrgEnrollCompletedProps) => {
    const { organization } = useContext(EnrollmentContext);
    const history = useHistory();
    const { id: orgId = '' } = organization;

    const onMicroDepositDone = useCallback(async () => {
        history.replace(getPath(Routes.StartProgramCompleted, {}));
    }, [history]);

    const onPlaidExit: PlaidLinkOnExit = useCallback(
        async (error) => {
            if (orgId && error && error.error_code === PlaidErrorCode.TooManyVerificationAttempts) {
                await deleteOrgAchAccount(orgId);
                history.replace(Routes.StartProgramEarnings);
            }
        },
        [orgId, history]
    );

    const TitleInfo = (
        <>
            <span className="text-3xl font-light lg:pr-32">
                Thanks for signing up! You’re almost ready to start fundraising for{' '}
                {organization.name}.
            </span>
            <br />
            <span className="font-bold">Now it's on us.</span>
        </>
    );

    const WhatsNext = (
        <>
            <p>We will review your information to confirm your organization's eligibility.</p>
            <br />
            <p>
                Once your organization is approved, you will receive an email letting you know your
                account is ready. This typically takes 1-2 business days. We will contact you if we
                require any additional information to finalize your program set-up.
            </p>
            {status === 'deposits' && (
                <>
                    <br />
                    <p>
                        A $0.01 deposit from Plaid should appear in your bank account after 1-2
                        business days. Return here to complete your setup by entering the code.
                        (Hint: your code is the first 3 letters after the #.)
                    </p>
                    <br />
                    <PlaidWrapper
                        orgId={orgId}
                        onSuccess={onMicroDepositDone}
                        onExit={onPlaidExit}
                        isResuming
                    >
                        {(openPlaid, isPlaidReady) => (
                            <Btn
                                disabled={!isPlaidReady}
                                onClick={openPlaid}
                                className="w-full sm:w-auto"
                            >
                                Complete Setup
                            </Btn>
                        )}
                    </PlaidWrapper>
                    <EmailCheckSection />
                </>
            )}
        </>
    );

    return (
        <BaseView titleInfo={TitleInfo} nextSteps={WhatsNext} curatedArticles={curatedArticles} />
    );
};

export const OrgEnrollCompleted = (props: OrgEnrollCompletedProps) => {
    const { status } = useParams<OrgEnrollCompletedParams>();
    const View = status !== 'paper-only' ? DepositsView : PaperOnlyView;

    useEffect(() => {
        localStorage.removeItem('link-token');
    }, []);

    return <View status={status} curatedArticles={props.curatedArticles} />;
};
