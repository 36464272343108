export enum MetadataPage {
    'brands',
    'on-bonus',
    'shop-online-bonus',
    'favorites',
    'featured-brands',
    'shop',
    'shop-online',
    'gift-cards',
    'pdp',
    'shop/order',
    'shop/account',
    'shop/wallet',
    'enroll',
    'enroll/start-program',
    'enroll/join-program',
    'category',
    'default',
    'invalidAccess',
}

export type MetadataMap = {
    [key in MetadataPage]: {
        charset: string;
        author: string;
        metaDescription: Function;
        title: Function;
        shortcutIcon: string;
        ogDescription: Function;
        ogTitle: Function;
        twitterDescription: Function;
        twitterTitle: Function;
        robots: string;
    };
};

export const metadataMap: MetadataMap = {
    [MetadataPage.brands]: {
        charset: 'utf-8',
        author: 'RaiseRight',
        metaDescription: () =>
            "RaiseRight partners with 750+ of the world's top brands to help nonprofit organizations raise millions of dollars using gift cards. Learn more & start earning.",
        title: () => 'Brands We Work With • RaiseRight',
        shortcutIcon: '/hubfs/favicon@3x%20(2).png',
        ogDescription: () =>
            "RaiseRight partners with 750+ of the world's top brands to help nonprofit organizations raise millions of dollars using gift cards. Learn more & start earning.",
        ogTitle: () => 'Brands We Work With • RaiseRight',
        twitterDescription: () =>
            "RaiseRight partners with 750+ of the world's top brands to help nonprofit organizations raise millions of dollars using gift cards. Learn more & start earning.",
        twitterTitle: () => 'Brands We Work With • RaiseRight',
        robots: '',
    },
    [MetadataPage['on-bonus']]: {
        charset: 'utf-8',
        author: 'RaiseRight',
        metaDescription: () =>
            'Shop RaiseRight brands on bonus to earn even more with your gift card purchases.',
        title: () => 'Gift Card Brands on Bonus | RaiseRight',
        shortcutIcon: '/hubfs/favicon@3x%20(2).png',
        ogDescription: () =>
            'Shop RaiseRight brands on bonus to earn even more with your gift card purchases.',
        ogTitle: () => 'Gift Card Brands on Bonus | RaiseRight',
        twitterDescription: () =>
            'Shop RaiseRight brands on bonus to earn even more with your gift card purchases.',
        twitterTitle: () => 'Gift Card Brands on Bonus | RaiseRight',
        robots: 'noindex',
    },
    [MetadataPage['shop-online-bonus']]: {
        charset: 'utf-8',
        author: 'RaiseRight',
        metaDescription: () =>
            'Shop RaiseRight brands on bonus to earn even more with your online purchases.',
        title: () => 'Online Shopping Brands on Bonus | RaiseRight',
        shortcutIcon: '/hubfs/favicon@3x%20(2).png',
        ogDescription: () =>
            'Shop RaiseRight brands on bonus to earn even more with your online purchases.',
        ogTitle: () => 'Online Shopping Brands on Bonus | RaiseRight',
        twitterDescription: () =>
            'Shop RaiseRight brands on bonus to earn even more with your online purchases.',
        twitterTitle: () => 'Online Shopping Brands on Bonus | RaiseRight',
        robots: 'noindex',
    },
    [MetadataPage.favorites]: {
        charset: 'utf-8',
        author: 'RaiseRight',
        metaDescription: () =>
            'Save your favorite gift card brands for faster shopping with RaiseRight.',
        title: () => 'Favorite Gift Cards • RaiseRight',
        shortcutIcon: '/hubfs/favicon@3x%20(2).png',
        ogDescription: () =>
            'Save your favorite gift card brands for faster shopping with RaiseRight.',
        ogTitle: () => 'Favorite Gift Cards • RaiseRight',
        twitterDescription: () =>
            'Save your favorite gift card brands for faster shopping with RaiseRight.',
        twitterTitle: () => 'Favorite Gift Cards • RaiseRight',
        robots: 'noindex',
    },
    [MetadataPage['featured-brands']]: {
        charset: 'utf-8',
        author: 'RaiseRight',
        metaDescription: () =>
            'RaiseRight partners with hundreds of gift card brands to raise funds for nonprofit organizations. See the most popular brands.',
        title: () => 'Featured Brands • RaiseRight',
        shortcutIcon: '/hubfs/favicon@3x%20(2).png',
        ogDescription: () =>
            'RaiseRight partners with hundreds of gift card brands to raise funds for nonprofit organizations. See the most popular brands.',
        ogTitle: () => 'Featured Brands • RaiseRight',
        twitterDescription: () =>
            'RaiseRight partners with hundreds of gift card brands to raise funds for nonprofit organizations. See the most popular brands.',
        twitterTitle: () => 'Featured Brands • RaiseRight',
        robots: 'noindex',
    },
    [MetadataPage.shop]: {
        charset: 'utf-8',
        author: 'RaiseRight',
        metaDescription: () =>
            'Shop 750+ gift card brands that give back to support fundraising for nonprofit organizations. ',
        title: () => 'Shop All Gift Cards • RaiseRight',
        shortcutIcon: '/hubfs/favicon@3x%20(2).png',
        ogDescription: () =>
            'Shop 750+ gift card brands that give back to support fundraising for nonprofit organizations. ',
        ogTitle: () => 'Shop All Gift Cards • RaiseRight',
        twitterDescription: () =>
            'Shop 750+ gift card brands that give back to support fundraising for nonprofit organizations. ',
        twitterTitle: () => 'Shop All Gift Cards • RaiseRight',
        robots: 'noindex',
    },
    [MetadataPage['shop-online']]: {
        charset: 'utf-8',
        author: 'RaiseRight',
        metaDescription: () =>
            'Shop online across hundreds of brands to fundraise and earn for your favorite nonprofit organizations. ',
        title: () => 'Shop Online and Earn • RaiseRight',
        shortcutIcon: '/hubfs/favicon@3x%20(2).png',
        ogDescription: () =>
            'Shop online across hundreds of brands to fundraise and earn for your favorite nonprofit organizations. ',
        ogTitle: () => 'Shop Online and Earn • RaiseRight',
        twitterDescription: () =>
            'Shop online across hundreds of brands to fundraise and earn for your favorite nonprofit organizations. ',
        twitterTitle: () => 'Shop Online and Earn • RaiseRight',
        robots: 'noindex',
    },
    [MetadataPage['gift-cards']]: {
        charset: 'utf-8',
        author: 'RaiseRight',
        metaDescription: () =>
            'Shop gift cards for hundreds of brands to fundraise and earn for your favorite nonprofit organizations. ',
        title: () => 'Shop Gift Cards and Earn • RaiseRight',
        shortcutIcon: '/hubfs/favicon@3x%20(2).png',
        ogDescription: () =>
            'Shop gift cards for hundreds of brands to fundraise and earn for your favorite nonprofit organizations. ',
        ogTitle: () => 'Shop Gift Cards and Earn • RaiseRight',
        twitterDescription: () =>
            'Shop gift cards for hundreds of brands to fundraise and earn for your favorite nonprofit organizations. ',
        twitterTitle: () => 'Shop Gift Cards and Earn • RaiseRight',
        robots: 'noindex',
    },
    [MetadataPage.pdp]: {
        charset: 'utf-8',
        author: 'RaiseRight',
        metaDescription: (brandName: string) =>
            `Raise money for your nonprofit organization with ${brandName} gift card fundraising on the RaiseRight platform. Learn more & start earning.`,
        title: (brandName: string) => `${brandName} • RaiseRight`,
        shortcutIcon: '/hubfs/favicon@3x%20(2).png',
        ogDescription: (brandName: string) =>
            `Raise money for your nonprofit organization with ${brandName} gift card fundraising on the RaiseRight platform. Learn more & start earning.`,
        ogTitle: (brandName: string) => `${brandName} • RaiseRight`,
        twitterDescription: (brandName: string) =>
            `Raise money for your nonprofit organization with ${brandName} gift card fundraising on the RaiseRight platform. Learn more & start earning.`,
        twitterTitle: (brandName: string) => `${brandName} • RaiseRight`,
        robots: '',
    },
    [MetadataPage['shop/order']]: {
        charset: 'utf-8',
        author: 'RaiseRight',
        metaDescription: () =>
            'Place your gift card order with RaiseRight. Check out in minutes and earn for your organization.',
        title: () => 'Place Order • RaiseRight',
        shortcutIcon: '/hubfs/favicon@3x%20(2).png',
        ogDescription: () =>
            'Place your gift card order with RaiseRight. Check out in minutes and earn for your organization. ',
        ogTitle: () => 'Place Order • RaiseRight',
        twitterDescription: () =>
            'Place your gift card order with RaiseRight. Check out in minutes and earn for your organization.',
        twitterTitle: () => 'Place Order • RaiseRight',
        robots: 'noindex',
    },
    [MetadataPage['shop/account']]: {
        charset: 'utf-8',
        author: 'RaiseRight',
        metaDescription: () =>
            'Access your RaiseRight account to view your earnings, check your order history, or update your personal information. ',
        title: () => 'Account • RaiseRight',
        shortcutIcon: '/hubfs/favicon@3x%20(2).png',
        ogDescription: () =>
            'Access your RaiseRight account to view your earnings, check your order history, or update your personal information. ',
        ogTitle: () => 'Account • RaiseRight',
        twitterDescription: () =>
            'Access your RaiseRight account to view your earnings, check your order history, or update your personal information. ',
        twitterTitle: () => 'Account • RaiseRight',
        robots: 'noindex',
    },
    [MetadataPage['shop/wallet']]: {
        charset: 'utf-8',
        author: 'RaiseRight',
        metaDescription: () =>
            'Your RaiseRight gift card wallet makes it easy to view, manage, and use your purchased gift cards. ',
        title: () => 'Gift Card Wallet • RaiseRight',
        shortcutIcon: '/hubfs/favicon@3x%20(2).png',
        ogDescription: () =>
            'Your RaiseRight gift card wallet makes it easy to view, manage, and use your purchased gift cards. ',
        ogTitle: () => 'Gift Card Wallet • RaiseRight',
        twitterDescription: () =>
            'Your RaiseRight gift card wallet makes it easy to view, manage, and use your purchased gift cards. ',
        twitterTitle: () => 'Gift Card Wallet • RaiseRight',
        robots: 'noindex',
    },
    [MetadataPage.enroll]: {
        charset: 'utf-8',
        author: 'RaiseRight',
        metaDescription: () =>
            'Enroll today to start earning for your organization with gift card fundraising from RaiseRight. ',
        title: () => 'Enroll • RaiseRight',
        shortcutIcon: '/hubfs/favicon@3x%20(2).png',
        ogDescription: () =>
            'Enroll today to start earning for your organization with gift card fundraising from RaiseRight. ',
        ogTitle: () => 'Enroll • RaiseRight',
        twitterDescription: () =>
            'Enroll today to start earning for your organization with gift card fundraising from RaiseRight. ',
        twitterTitle: () => 'Enroll • RaiseRight',
        robots: 'noindex',
    },
    [MetadataPage['enroll/start-program']]: {
        charset: 'utf-8',
        author: 'RaiseRight',
        metaDescription: () =>
            'Start a free gift card fundraising program for your nonprofit organization with RaiseRight and start earning. ',
        title: () => 'Start a Program • RaiseRight',
        shortcutIcon: '/hubfs/favicon@3x%20(2).png',
        ogDescription: () =>
            'Start a free gift card fundraising program for your nonprofit organization with RaiseRight and start earning. ',
        ogTitle: () => 'Start a Program • RaiseRight',
        twitterDescription: () =>
            'Start a free gift card fundraising program for your nonprofit organization with RaiseRight and start earning. ',
        twitterTitle: () => 'Start a Program • RaiseRight',
        robots: 'noindex',
    },
    [MetadataPage['enroll/join-program']]: {
        charset: 'utf-8',
        author: 'RaiseRight',
        metaDescription: () =>
            "Join your organization's gift card fundraising program with RaiseRight and start earning. ",
        title: () => 'Join a Program • RaiseRight',
        shortcutIcon: '/hubfs/favicon@3x%20(2).png',
        ogDescription: () =>
            "Join your organization's gift card fundraising program with RaiseRight and start earning.",
        ogTitle: () => 'Join a Program • RaiseRight',
        twitterDescription: () =>
            "Join your organization's gift card fundraising program with RaiseRight and start earning. ",
        twitterTitle: () => 'Join a Program • RaiseRight',
        robots: 'noindex',
    },
    [MetadataPage.category]: {
        charset: 'utf-8',
        author: 'RaiseRight',
        metaDescription: () =>
            "RaiseRight partners with 750+ of the world's top brands to help nonprofit organizations raise millions of dollars using gift cards. Learn more & start earning.",
        title: () => 'Gift Cards by Category • RaiseRight',
        shortcutIcon: '/hubfs/favicon@3x%20(2).png',
        ogDescription: () => 'Shop gift card brands by category and earn more with RaiseRight.',
        ogTitle: () => 'Gift Cards by Category • RaiseRight',
        twitterDescription: () =>
            'Shop gift card brands by category and earn more with RaiseRight.',
        twitterTitle: () => 'Gift Cards by Category • RaiseRight',
        robots: 'noindex',
    },
    [MetadataPage.default]: {
        charset: 'utf-8',
        author: 'RaiseRight',
        metaDescription: () => 'Start a free program for gift card fundraising with RaiseRight',
        title: () => 'RaiseRight - Gift Card Fundraiser',
        shortcutIcon: '/hubfs/favicon@3x%20(2).png',
        ogDescription: () => 'Start a free program for gift card fundraising with RaiseRight',
        ogTitle: () => 'RaiseRight - Gift Card Fundraiser',
        twitterDescription: () => 'Start a free program for gift card fundraising with RaiseRight',
        twitterTitle: () => 'RaiseRight - Gift Card Fundraiser',
        robots: '',
    },
    [MetadataPage.invalidAccess]: {
        charset: 'utf-8',
        author: 'RaiseRight',
        metaDescription: () => 'Invalid Access Attempt',
        title: () => 'RaiseRight - Invalid Access Attempt',
        shortcutIcon: '/hubfs/favicon@3x%20(2).png',
        ogDescription: () => 'RaiseRight - Invalid Access Attempt',
        ogTitle: () => 'RaiseRight - Invalid Access Attempt',
        twitterDescription: () => 'RaiseRight - Invalid Access Attempt',
        twitterTitle: () => 'RaiseRight - Invalid Access Attempt',
        robots: 'noindex',
    },
};
