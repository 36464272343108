import React, { DependencyList, EffectCallback, useEffect, useRef } from 'react';
import { escapeStringRegExp, highlightString } from 'common/utils/strings';

export const formatPercentageShort = (value: number, decimalSize = 1) =>
    `${value % 1 === 0 ? value.toFixed(0) : value.toFixed(decimalSize)}%`;

export const copyTextToClipboard = (text: string) => {
    if ('clipboard' in navigator) {
        return navigator.clipboard.writeText(text);
    }
    return document.execCommand('copy', true, text);
};

// Returns the date difference between today and the 'pastDate' in a
// user-friendly format (e.g. "Yesterday", "Last month", "Last Year".)
// The returned date is not complete/exact (e.g. it is not possible to
// recreate the exact 'pastDate' due to precision loss.)
// Fuzzy dates: https://kb.blackbaud.com/knowledgebase/Article/41804
// eslint-disable-next-line complexity
export const getFuzzyDate = (pastDate: Date) => {
    const today = new Date();
    const delta = Math.round((today.valueOf() - pastDate.valueOf()) / 1000);

    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;

    if (delta < 30) {
        return 'just now.';
    }
    if (delta < minute) {
        return `${delta} seconds ago.`;
    }
    if (delta < 2 * minute) {
        return 'a minute ago.';
    }
    if (delta < hour) {
        return `${Math.floor(delta / minute)} minutes ago.`;
    }
    if (Math.floor(delta / hour) === 1) {
        return '1 hour ago.';
    }
    if (delta < day) {
        return `${Math.floor(delta / hour)} hours ago.`;
    }
    if (delta < day * 2) {
        return 'yesterday.';
    }
    if (delta < day * 31) {
        return `${Math.floor(30 * day)} days ago.`;
    }
    if (delta < day * 365) {
        let months = today.getMonth() - pastDate.getMonth();
        if (months < 0) {
            months += 12;
        }
        if (months <= 1) {
            return 'last month.';
        }
        return `${months} months ago.`;
    }

    const years = today.getFullYear() - pastDate.getFullYear();
    if (years <= 1) {
        return 'last year.';
    }

    return `${years} years ago.`;
};

export const highlightMatchesHtml = (content: string, highlightWord: string) => {
    const replaceRegex = new RegExp(escapeStringRegExp(highlightWord), 'gi');
    const parts = highlightString(content, replaceRegex);
    const result = (
        <>
            {parts.map((p) =>
                Array.isArray(p) ? (
                    <b key={p[0]}>{p[0]}</b>
                ) : (
                    <span key={p} className="normal-case">
                        {p}
                    </span>
                )
            )}
        </>
    );

    return result;
};

export const formatPhoneNumber = (phoneNumber: string) => {
    const match = phoneNumber.slice(phoneNumber.length - 10).match(/(\d{3})(\d{3})(\d{4})/);
    if (match) {
        const [, areaCode, firstPart, secondPart] = match;
        return `(${areaCode}) ${firstPart}-${secondPart}`;
    }
    return phoneNumber;
};

// hook to ignore the first call (when component renders)
export const useDidUpdateEffect = (fn: EffectCallback, inputs?: DependencyList) => {
    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current) {
            return fn();
        }
        didMountRef.current = true;
    }, [
        fn,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ...(inputs || []),
    ]);
};

export const isInIframe = () => window.self !== window.top;

export const isPaymentHost = () => {
    const hostname = window.location.hostname.toLowerCase();
    return hostname.startsWith('payment.') && hostname.endsWith('raiseright.com');
};

export const mainWebsiteOrigin = () => {
    if (location.hostname === 'localhost') {
        return 'https://localhost:5002';
    }
    const url = new URL(process.env.REACT_APP_RAISE_RIGHT_APP_URL || '');
    return url.origin;
};

export const paymentWebsiteOrigin = () => {
    if (location.hostname === 'localhost') {
        return 'https://localhost:5003';
    }

    const url = new URL(process.env.REACT_APP_RAISE_RIGHT_APP_URL || '');
    url.hostname = 'payment.' + url.hostname;
    return url.origin;
};

export const getPaymentWindowUrl = (location: Location) => {
    if (location.hostname === 'localhost') {
        return location.href.replace('localhost:5002', 'localhost:5003');
    }
    const url = new URL(location.href);
    url.hostname = 'payment.' + url.hostname;
    return url.href;
};

export const RunNewCoordinatorDashboardWelcomeMessageLogic = (id: string | null) => {
    // NOTE: NEW-COORDINATOR-DASHBOARD(FEC-1221 & FEC-1174)
    // remove the Welcome Message if user's session has expired and also updates
    // flag in local storage to signal next time user comes back to show greetings message

    const userId = id?.substr(0, 8) || '';
    const hasSeenWelcomeMessage = window.localStorage.getItem(`${userId}_has_seen_welcome_message`);
    if (hasSeenWelcomeMessage) {
        if (hasSeenWelcomeMessage === 'false') {
            // NOTE: Marking as seen so the user doesn't see welcome message _again_
            window.localStorage.setItem(`${userId}_has_seen_welcome_message`, 'true');
        }
        if (window.localStorage.getItem(`${userId}_tempidx`) === '0') {
            window.localStorage.setItem(`${userId}_tempidx`, '1');
        }
    }
};
