import React, { useState, SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';
import * as RuiDropdownMenu from '@radix-ui/react-dropdown-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { useRootSelector } from 'rootStore';
import { createStyles } from 'utils/createStyle';
import { HomeDetailsResponse } from 'common/api/crm/home/models/HomeDetailsResponse';
import { selectedOrganization, startOrgSwitchFlow } from 'common/features/store/duck/home/duck';
import { colors } from 'styles/settings';
import { OrganizationMembership } from 'common/api/crm/models/OrganizationMembership';

const styles = createStyles({
    selectOrgButton: [
        'py-2 pl-8 pr-10 hover:bg-grey-accent cursor-pointer text-left text-sm text-grey-1 hover:text-brand focus:outline-none rounded',
        {
            border: '2px solid white',
            ':hover:not(:focus)': {
                border: `2px solid ${colors.greyAccent}`,
            },
            ':focus': { border: `2px solid ${colors.brand}` },
        },
    ],
    selectOrgDropDown: [
        'header_org_switch_menu flex items-center text-brand p-2 pr-0 cursor-pointer mr-1',
        { fontSize: 12, lineHeight: '14px', fontWeight: 400 },
    ],
    selectOrgDropDownContent: [
        'flex flex-col bg-white shadow-xl border-solid border-1 rounded border-brand',
        { fontSize: 12, lineHeight: '14px' },
    ],
    selectOrgDropDownContentSubtitle: [
        'text-grey-2 py-3 pl-4 pr-10',
        { fontSize: 12, lineHeight: '14px' },
    ],
});

export const OrgSelectDropdown = ({
    homeDetails,
    redirectRoute,
}: {
    homeDetails: HomeDetailsResponse | null;
    redirectRoute: string;
}) => {
    const dispatch = useDispatch<any>();
    const [orgSelectIsOpen, setOrgSelectIsOpen] = useState(false);
    const selectedOrg = useRootSelector((state) => selectedOrganization(state));
    const membershipList = Object.values(homeDetails?.organizations || {}).filter(
        (membership: OrganizationMembership) =>
            membership.isActive && membership.organization.isActive
    );
    const hasMultipleOrgs = membershipList.length > 1;
    const currentOrg = homeDetails?.defaultOrganization?.id || '';

    const onOrgSelect = (orgId: string) => (evt: SyntheticEvent) => {
        evt.preventDefault();
        setOrgSelectIsOpen(false);

        dispatch(
            startOrgSwitchFlow({
                orgId: orgId,
                previousOrgId: currentOrg,
                redirectRoute: redirectRoute,
            })
        );
    };

    const Divider = () => <div style={{ height: 1 }} className="mb-2 bg-grey-6" />;

    return (
        <RuiDropdownMenu.Root
            open={orgSelectIsOpen}
            onOpenChange={() => setOrgSelectIsOpen(!orgSelectIsOpen)}
        >
            <RuiDropdownMenu.Trigger>
                <div className={styles.selectOrgDropDown}>
                    <span>
                        {`${homeDetails?.profile.firstName}`}{' '}
                        {selectedOrg?.organization.name && ` - ${selectedOrg?.organization.name}`}
                    </span>
                    <FontAwesomeIcon
                        icon={orgSelectIsOpen ? faCaretUp : faCaretDown}
                        className="ml-3 mr-1.5"
                        size="lg"
                    />
                </div>
            </RuiDropdownMenu.Trigger>
            <RuiDropdownMenu.Content className={styles.selectOrgDropDownContent}>
                <p className={styles.selectOrgDropDownContentSubtitle}>You are fundraising for</p>
                <div className="mb-4 mr-6">
                    <FontAwesomeIcon
                        icon={faCheck}
                        className="text-brand-bonus-pink ml-4 mr-1.5 mb-0"
                        size="lg"
                    />
                    <span className="text-grey-1 text-sm font-semibold">
                        {selectedOrg?.organization.name}
                    </span>
                </div>
                {hasMultipleOrgs && (
                    <>
                        <Divider />
                        <p className={styles.selectOrgDropDownContentSubtitle}>Switch to</p>
                    </>
                )}
                {membershipList.map(
                    (membership) =>
                        membership.organization.id !== selectedOrg?.organization.id && (
                            <button
                                key={membership.organization.id}
                                className={styles.selectOrgButton}
                                disabled={
                                    membership.organization.id ===
                                    homeDetails?.defaultOrganization?.id
                                }
                                onClick={onOrgSelect(membership.organization.id)}
                            >
                                {membership.organization.name}
                            </button>
                        )
                )}
            </RuiDropdownMenu.Content>
        </RuiDropdownMenu.Root>
    );
};
