import { Family } from '../models/Family';
import { OrganizationMembership } from '../models/OrganizationMembership';

import { FAMILY_URL } from 'common/api/config';
import { APIResponse } from 'common/api/models';
import {
    HomeDetailsResponse,
    SelectedOrganization,
} from 'common/api/crm/home/models/HomeDetailsResponse';
import { withTokensIfNeeded } from 'common/api/utils/withTokensIfNeeded';

function buildOrgMap(orgList: OrganizationMembership[]) {
    return orgList.reduce(
        (orgs, orgSummary) => ({
            ...orgs,
            [orgSummary.organization.id]: orgSummary,
        }),
        {}
    );
}

//TODO: this call should be refactored into indivdual API calls so that error handling can be
// managed on a per call basis.
export const _getHomeDetails = async (): Promise<APIResponse<HomeDetailsResponse>> => {
    const [familyResponse, defaultOrgResponse, orgsResponse] = await Promise.all([
        withTokensIfNeeded<Family>(`${FAMILY_URL}?api-version=1`),
        withTokensIfNeeded<HomeDetailsResponse['defaultOrganization']>(
            `${FAMILY_URL}/organization/default?api-version=1`
        ),
        withTokensIfNeeded<OrganizationMembership[]>(`${FAMILY_URL}/organizations?api-version=1`),
    ]);

    if (familyResponse.error) {
        return familyResponse;
    }

    // 404 is a valid response and we should store null in state for these objects
    // if we get an error other than 404 we want to propagate the error to the AsyncAction
    // portion of state.
    if (defaultOrgResponse.error && defaultOrgResponse.error?.response?.status !== 404) {
        return defaultOrgResponse;
    }
    if (orgsResponse.error && orgsResponse.error?.response?.status !== 404) {
        return orgsResponse;
    }

    const defaultOrgDetails = orgsResponse.data?.find(({ organization }) => {
        if (defaultOrgResponse && defaultOrgResponse.data) {
            return organization.id === defaultOrgResponse.data.id;
        }
    });

    const defaultOrg = {
        ...defaultOrgResponse.data,
        // Default to lower access level if something goes wrong and we don't
        // have the data
        isCoordinator: defaultOrgDetails ? defaultOrgDetails.isCoordinator : false,
    } as SelectedOrganization;

    return {
        data: {
            profile: familyResponse.data,
            selectedOrgId: defaultOrgResponse.data?.id || null,
            defaultOrganization: defaultOrgResponse.data ? defaultOrg : null,
            organizations: orgsResponse && orgsResponse.data ? buildOrgMap(orgsResponse.data) : {},
        },
    };
};
