import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from 'styles/settings';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalSize, ModalFooter, ModalSecondaryBtn } from 'components/modal/Modal';
import { Btn } from 'components/btn/Btn';
import { Organization } from 'common/api/crm/models/Organization';
import { withRetries } from 'common/utils/withRetries';
import { getOrganization, updateOrganization } from 'common/api/crm/organization/service';
import {
    selectSelectedOrgId,
    updateDefaultOrgP2pInviteSetting,
} from 'common/features/store/duck/home/duck';
import { useDispatch, useSelector } from 'react-redux';

export const useP2PInviteSettings = () => {
    const dispatch = useDispatch();
    const orgId = useSelector(selectSelectedOrgId);
    const [organization, setOrganization] = useState<Organization>();
    const isP2PInviteEnabled = useMemo(() => organization?.isP2PInviteEnabled, [organization]);

    const fetchOrganization = useCallback(async () => {
        if (!orgId) {
            return;
        }
        const org = (await withRetries(getOrganization)(orgId)).data;

        if (!org) {
            return;
        }
        setOrganization(org);

        return org;
    }, [orgId, setOrganization]);

    const fetchedOrganizationRef = useRef(false);
    useEffect(() => {
        if (orgId && !fetchedOrganizationRef.current) {
            fetchOrganization();
            fetchedOrganizationRef.current = true;
        }
    }, [orgId, fetchOrganization]);

    const [isUpdatingP2PInviteSettings, setIsUpdatingP2PInviteSettings] = useState(false);

    const updateP2PInviteSettings = async (newValue: boolean) => {
        try {
            setIsUpdatingP2PInviteSettings(true);

            // retrieves the most recent organization
            // to minimize issues with concurrency.
            const org = await fetchOrganization();
            if (!org) {
                return;
            }

            const updatedOrg: Organization = {
                ...org,
                isP2PInviteEnabled: newValue,
            };
            const updateOrganizationResponse = await withRetries(updateOrganization)(updatedOrg);

            if (updateOrganizationResponse.response?.ok) {
                // update the org to refresh the isP2PInviteEnabled flag.
                setOrganization({ ...updatedOrg });
                // Lets update homeDetails/default-organization
                dispatch(updateDefaultOrgP2pInviteSetting(newValue));
            }

            return updateOrganizationResponse.response?.ok;
        } finally {
            setIsUpdatingP2PInviteSettings(false);
        }
    };

    return {
        isP2PInviteEnabled,
        updateP2PInviteSettings,
        isUpdatingP2PInviteSettings,
    };
};

const ENABLE_P2P_INVITE_CONFIRM_MODAL_PROPS = {
    title: 'Reminder about sharing codes',
    body: `Please ensure that invites are only sent to people associated with your organization.
    Posting invites on public social media pages or websites can lead to fraudulent enrollments or unwanted activity.`,
    confirmText: 'Enable Peer-to-Peer Invites',
};

const DISABLE_P2P_INVITE_CONFIRM_MODAL_PROPS = {
    title: 'Notice about disabling invites',
    body: `Disabling this feature immediately deactivates all participant invite
    codes and links, including any that have been sent but not yet accepted.`,
    confirmText: 'Disable Peer-to-Peer Invites',
};

export const useP2PConfirmModal = ({
    isP2PInviteEnabled,
    onConfirm,
}: {
    isP2PInviteEnabled: boolean | undefined;
    onConfirm: () => void;
}) => {
    const [showConfirmationWarning, setShowConfirmationWarning] = useState(false);
    const closeP2PConfirmationWarning = () => setShowConfirmationWarning(false);

    const { title, body, confirmText } = isP2PInviteEnabled
        ? DISABLE_P2P_INVITE_CONFIRM_MODAL_PROPS
        : ENABLE_P2P_INVITE_CONFIRM_MODAL_PROPS;

    const viewP2PConfirmationWarning = (
        <Modal
            title=""
            isOpen={showConfirmationWarning}
            onClose={closeP2PConfirmationWarning}
            showCloseButton={true}
            size={[ModalSize.LARGE, ModalSize.LARGE]}
        >
            <div className="flex flex-col items-center">
                <FontAwesomeIcon
                    color={colors.warningDark}
                    icon={faExclamationTriangle}
                    size="2x"
                />
                <div className="mt-6 text-center w-auto sm:w-10/12 ">
                    <p className="text-3xl font-semibold mb-4">{title}</p>
                    <p className="text-grey-2 font-normal text-md pb-5 max-w-xl">{body}</p>
                </div>
            </div>
            <div className="sm:-mt-3 mb-3">
                <ModalFooter>
                    <Btn onClick={onConfirm} type="button" className="px-2">
                        {confirmText}
                    </Btn>
                    <ModalSecondaryBtn onClick={closeP2PConfirmationWarning}>
                        Cancel
                    </ModalSecondaryBtn>
                </ModalFooter>
            </div>
        </Modal>
    );

    return {
        setShowConfirmationWarning,
        viewP2PConfirmationWarning,
    };
};
