import { PaymentMethod } from 'common/api/e-comm/payment-types';
import { FamilyAddress } from 'common/api/crm/family/address/models/FamilyAddress';

export interface CheckoutRequest {
    cardTolinkWithClo?: number;
    newCreditCard: PayWithCreditCardRequest;
    newDebitCard: PayWithCreditCardRequest;
    useNewCreditCard: boolean;
    useNewDebitCard: boolean;
    paymentMethod: PaymentMethod | null;
    shouldUpdateDefaultPayment: boolean;
    savePaymentDetails: boolean;
}

export const emptyCreditCard: PayWithCreditCardRequest = {
    name: '',
    cardNumber: '',
    expirationDate: '',
    securityCode: '',
    save: true,
    address: null,
    zipCode: '',
};

export const emptyDebitCard: PayWithDebitCardRequest = {
    name: '',
    cardNumber: '',
    expirationDate: '',
    securityCode: '',
    save: true,
    address: null,
    zipCode: '',
};

export const emptyCheckoutRequest: CheckoutRequest = {
    cardTolinkWithClo: 0,
    newCreditCard: emptyCreditCard,
    newDebitCard: emptyDebitCard,
    useNewCreditCard: false,
    useNewDebitCard: false,
    paymentMethod: null,
    shouldUpdateDefaultPayment: false,
    savePaymentDetails: true,
};

export interface PayWithCreditCardRequest {
    name: string;
    cardNumber: string;
    expirationDate: string;
    securityCode: string;
    save: boolean;
    address: FamilyAddress | null;
    zipCode: string;
}

export interface PayWithDebitCardRequest extends PayWithCreditCardRequest {}
