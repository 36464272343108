import { SECURED_E_COMM_URL } from 'common/api/config';
import { withTokensIfNeeded } from 'common/api/utils/withTokensIfNeeded';
import {
    FavoritesBrandDataModel,
    BrandCatalogSources,
    BrandCatalogIdPrefixes,
    BrandSources,
    FavoriteId,
} from 'common/api/e-comm/models/FavoriteBrandDataModel';

// Old version that supports RaiseRightBrands only(our normal catalog)
export const getFavorites_ = () =>
    withTokensIfNeeded<number[]>(`${SECURED_E_COMM_URL}/brands/favorite`);

// NOTE: New version that supports Our catalog + 3rd party ones(e.g FlipGive)
export const getFavorites = () =>
    withTokensIfNeeded<FavoritesBrandDataModel[]>(
        `${SECURED_E_COMM_URL}/brands/favorite?api-version=2`
    );

export const favoriteBrand = (brandId: FavoriteId, source: BrandCatalogSources) =>
    withTokensIfNeeded(`${SECURED_E_COMM_URL}/Brands/${brandId}/favorite?source=${source}`, {
        method: 'POST',
    });

export const unfavoriteBrand = (brandId: FavoriteId, source: BrandCatalogSources) =>
    withTokensIfNeeded(`${SECURED_E_COMM_URL}/Brands/${brandId}/favorite?source=${source}`, {
        method: 'DELETE',
    });

// Helpers
export const parseFavoriteId = (favorite: FavoritesBrandDataModel): FavoriteId => {
    if (favorite.source == BrandCatalogSources.FlipGive) {
        const prefix = BrandCatalogIdPrefixes[BrandCatalogSources.FlipGive];
        return `${prefix}${favorite.id}`;
    }
    return `${favorite.id}`;
};

export const getBrandCatalogSourceFromId = (id: FavoriteId) => {
    if (Number.isFinite(id) || (typeof id === 'string' && !id.includes('-'))) {
        return BrandCatalogSources.RaiseRight;
    } else if (typeof id === 'string' && id.includes(BrandSources.FlipGive)) {
        return BrandCatalogSources.FlipGive;
    }
    return '';
};
