export enum BrandCatalogSources {
    RaiseRight = 'RaiseRight',
    FlipGive = 'FlipGive',
}

// NOTE: We'll wanna replace anywhere we see `affiliate-` in the codebase with this
// enum
export enum BrandSources {
    RaiseRight = '',
    FlipGive = 'affiliate-',
}

export const BrandCatalogIdPrefixes: Record<BrandCatalogSources, BrandSources> = {
    [BrandCatalogSources.FlipGive]: BrandSources.FlipGive,

    // NOTE: Should not be used just placing to satisfy types :'(
    [BrandCatalogSources.RaiseRight]: BrandSources.RaiseRight,
};

export interface FavoritesBrandDataModel {
    source: BrandCatalogSources;
    id: number;
}

export type FavoriteId = string | number;
