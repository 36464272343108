import {
    ConstructorReturnObject,
    checkAffiliateBonusExpiration,
    checkBonusExpiration,
} from 'common/api/constructor/util';
import { Bonus } from 'common/api/search/models/CanonicalSearchResultBrand';
import { findMaxRebate } from 'common/features/store/duck/brands/utils/rebates';
import { Brand } from 'common/features/store/duck/search-generic/utils/searchResultBrandsToBrands';
import lodash from 'lodash';

const camelCaseObj = (item: ConstructorReturnObject): Brand => {
    return {
        name: item['value'] || '',
        ...lodash.mapKeys(item['data'] as {}, (_, key) => {
            if (key === 'group_ids') {
                return 'suggestions';
            }
            return lodash.camelCase(key);
        }),
        isSponsored: item.is_slotted && item.labels?.is_sponsored,
    } as Brand;
};

export const convertConstructorItemToBrand = (item: ConstructorReturnObject) => {
    const camelCasedData = camelCaseObj(item);
    const rebate =
        (camelCasedData as unknown as { rebate: number[] }).rebate.length > 0
            ? findMaxRebate((camelCasedData as unknown as { rebate: number[] }).rebate)
            : 0;
    (camelCasedData as unknown as { rebate: number }).rebate = rebate;
    return camelCasedData as unknown as Brand;
};

export const constructorDataToBrandData = (item: ConstructorReturnObject): Brand => {
    const data: Brand = {
        id: item.data.id,
        name: item.value,
        rebate: item.data.Rebate.length ? item.data.Rebate[0] : 0,
        isOnBonus: false,
        // NOTE: We _never_ access this property
        isFeatured: item.data.IsFeatured,
        imageUrl: item.data.image_url,
        // NOTE: We _never_ access this property -- only native app on search(cognitive)
        keyWords: [],
        bonusRebate: undefined,
        expiration: undefined,
        isLocalProduct: item.data.IsLocalProduct,
        // NOTE: We _never_ access this property -- only native app on search(cognitive)
        productIds: [],
        supportedTypes: getSupportedTypes(item.data.facets),
        categories: item.data.group_ids,
        urlSlug: item.data.UrlSlug,
        url: item.data.url,
        newBannerExpiration: item.data.NewBannerExpiration
            ? new Date(item.data.NewBannerExpiration)
            : null,
        isSponsored: Boolean(item.is_slotted && item.labels['is_sponsored']),

        // flipgive data
        affiliateBonus: item.data.AffiliateBonus,
        affiliateBonusEnd: item.data.AffiliateBonusEnd,
        affiliateBonusStart: item.data.AffiliateBonusStart,
        affiliateExclusionText: item.data.AffiliateExclusionText,
        affiliateOfferEarnText: item.data.AffiliateOfferEarnText,
        affiliateOfferTerms: item.data.AffiliateOfferTerms,
        affiliateOfferUrl: item.data.AffiliateOfferUrl,
        affiliateRebate: item.data.AffiliateRebate,
        affiliateVariablePricingText: item.data.AffiliateVariablePricingText,
        canDoubleDip: item.data.CanDoubleDip,
        containsAffiliateOffer: item.data.ContainsAffiliateOffer,
        containsVariableRebate: item.data.ContainsVariableRebate,
        isAffiliateOfferOnBonus: item.data.IsAffiliateOfferOnBonus,
        isRaiseRightBrand: item.data.IsRaiseRightBrand,
        isRaiseRightGiftCardOnBonusOrAffiliateOnBonus:
            item.data.IsRaiseRightGiftCardOnBonusOrAffiliateOnBonus,
        averagePurchaseDelayHours: item.data.AveragePurchaseDelayHours,
    };

    // Process Brand Expiration & Bonuses
    if (item.data.IsOnBonus) {
        const [bonusRebate, isOnBonus] = IsBrandOnBonus(item.data.Bonus);
        data.isOnBonus = isOnBonus;
        data.bonusRebate = bonusRebate ? bonusRebate.Rebate : undefined;
    }

    if (item.data.IsAffiliateOfferOnBonus) {
        const isAffiliateOfferOnBonus = checkAffiliateBonusExpiration(
            data.affiliateBonusStart,
            data.affiliateBonusEnd
        );
        data.isAffiliateOfferOnBonus = isAffiliateOfferOnBonus;
        if (!isAffiliateOfferOnBonus) {
            data.affiliateBonus = undefined;
        }
    }

    return data;
};

const IsBrandOnBonus = (bonuses?: Bonus[]): [Bonus | undefined, boolean] => {
    if (bonuses && bonuses.length > 0) {
        const latestBonusRebate = bonuses.reduce((highest, next) => {
            if (highest.Rebate !== next.Rebate) {
                return highest.Rebate > next.Rebate ? highest : next;
            }
            return new Date(highest.Expiration) > new Date(next.Expiration) ? highest : next;
        });
        const today = new Date();
        const bonusExpiration = new Date(latestBonusRebate.Expiration);
        return [latestBonusRebate, bonusExpiration.getTime() > today.getTime()];
    }
    // eslint-disable-next-line no-sparse-arrays
    return [, false];
};

const getSupportedTypes = (data: ConstructorReturnObject['data']['facets']): string[] => {
    for (let i = 0; i < data.length; i++) {
        if (data[i].name === 'SupportedTypes') {
            return data[i].values;
        }
    }
    return [];
};
