import React, { useState, createContext, useEffect } from 'react';

import { SiteHeader } from '../../components/search/SiteHeader';

import { SiteFooter } from 'components/search/SiteFooter';
import { useStateRebateAdjustment } from 'common/hooks/useStateRebateAdjustment';
import { FullScreenOverlay } from './OrgChangeOverlay/FullScreenOverlay';
import { useSelector } from 'react-redux';
import { selectOrgSwitchState } from 'common/features/store/duck/home/duck';

export interface ShopScreenContextType {
    showLoginDialog: boolean;
    setShowLoginDialog: (value: boolean) => void;
}

export const ShopScreenContext = createContext<ShopScreenContextType>({
    showLoginDialog: false,
    setShowLoginDialog: () => {
        throw new Error('NotImplemented');
    },
});

export const ShopScreen = ({
    children,
    footerRef,
    isEmbeddedView = false,
}: {
    children: React.ReactNode;
    footerRef?: React.RefObject<HTMLSpanElement>;
    isEmbeddedView?: boolean;
}) => {
    const [showLoginDialog, setShowLoginDialog] = useState(false);
    const { getStateRebateAdjustment } = useStateRebateAdjustment();
    const { showOverlay } = useSelector(selectOrgSwitchState);

    useEffect(() => {
        getStateRebateAdjustment();
    }, [getStateRebateAdjustment]);

    return (
        <ShopScreenContext.Provider value={{ showLoginDialog, setShowLoginDialog }}>
            <div>
                {!isEmbeddedView && <SiteHeader />}

                {showOverlay && <FullScreenOverlay />}

                <span ref={footerRef} className={!showOverlay ? 'inline' : 'hidden'}>
                    {children}
                    {!isEmbeddedView && <SiteFooter />}
                </span>
            </div>
        </ShopScreenContext.Provider>
    );
};
