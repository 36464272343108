import React, {
    ReactNode,
    useCallback,
    useMemo,
    useContext,
    useEffect,
    useLayoutEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronDown, ChevronUp } from 'react-feather';
import * as RuiAccordion from '@radix-ui/react-accordion';
import classNames from 'classnames';

import { createStyles } from 'utils/createStyle';
import { useRootSelector } from 'common/features/featuresReducer';
import {
    selectEnrollmentFlowControls,
    selectProgressBarRef,
    setCurrentStep,
} from 'common/features/store/duck/ui/org-enrollment-v2';
import { ContentFullEnrollmentContext } from 'features/coordinator/components/useContentfulEnrollment';
import { useSpring, animated } from 'react-spring';
import { useMeasure } from 'hooks/useMeasure';
import { scrollTo } from '../utils/ScrollTo';
import { useHistory } from 'react-router';
import { OrganizationEnrollmentV2 } from 'common/api/contentful/models/OrganizationEnrollment';

const styles = createStyles({
    accordionGroupHeader: [
        `text-brand md:mb-2 border-b-2 border-pale-blue md:border-none 
        rounded-none md:rounded-lg bg-white`,
        {
            '@media(min-width: 640px)': {
                boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.1)',
            },
        },
    ],
    accordionGroupButton: 'py-4 px-4 md:px-10 block w-full text-left focus:outline-none',
    root: 'max-w-full',
    title: 'inline-block align-top ml-auto',
    content: ['px-4 md:px-10 pb-8'],
    item: 'flex text-lg md:text-3xl font-bold text-grey-1',
    selectedItem: 'flex text-lg md:text-3xl font-bold text-brand-dark',
});

export interface AccordionFormItem {
    value: number;
    title: string;
    content: ReactNode;
}

export const AccordionForm = ({ items }: { items: AccordionFormItem[] }) => {
    const dispatch = useDispatch<any>();
    const { currentStep } = useRootSelector(selectEnrollmentFlowControls);
    const progressBarRef = useSelector(selectProgressBarRef);

    const onItemClicked = useCallback(
        (_value: string) => {
            if (_value) {
                scrollTo(progressBarRef);
                dispatch(setCurrentStep(parseInt(_value, 10)));
            }
        },
        [dispatch, progressBarRef]
    );

    const history = useHistory();

    useLayoutEffect(() => {
        const mapping = ['get-started', 'org-info', 'account'];
        // NOTE: We only need to add the `hash` for the accordion-steps
        if (currentStep < 3) {
            history.replace(
                `${history.location.pathname}${history.location.search}#${mapping[currentStep]}`
            );
        }
    }, [currentStep, history]);

    return (
        <div className="sm:max-w-xl">
            <RuiAccordion.Root
                type="single"
                value={currentStep.toString()}
                onValueChange={onItemClicked}
            >
                {items.map(({ value, content }) => (
                    <Step key={value} value={value} content={content} />
                ))}
            </RuiAccordion.Root>
        </div>
    );
};

const Step = ({ value, content }: { value: number; content: React.ReactNode }) => {
    const { currentStep, maxStep } = useRootSelector(selectEnrollmentFlowControls);
    const [st, animate] = useSpring(() => ({ height: 0, overflow: 'hidden' }), []);
    const titles = useTitlesFromContentful();

    const [ref, { height }] = useMeasure();

    useEffect(() => {
        animate({
            height: currentStep === value ? height : 0,
        });
        // NOTE: We don't want to run the effect when currentStep changes
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [height, value, animate]);

    return (
        <RuiAccordion.Item key={value} value={value.toString()}>
            <RuiAccordion.Header className={classNames(styles.accordionGroupHeader)}>
                <RuiAccordion.Button
                    className={classNames(
                        styles.accordionGroupButton,
                        currentStep !== value ? '' : ''
                    )}
                >
                    <div className={value === currentStep ? styles.selectedItem : styles.item}>
                        {titles[value]}
                        {value <= maxStep && (
                            <span className={styles.title}>
                                {value == currentStep ? <ChevronUp /> : <ChevronDown />}
                            </span>
                        )}
                    </div>
                </RuiAccordion.Button>
                <animated.div style={st}>
                    <div ref={ref}>
                        <RuiAccordion.Panel className={styles.content}>
                            {content}
                        </RuiAccordion.Panel>
                    </div>
                </animated.div>
            </RuiAccordion.Header>
        </RuiAccordion.Item>
    );
};

const useTitlesFromContentful = () => {
    const { contentfulEntry } = useContext(ContentFullEnrollmentContext);
    const entry = contentfulEntry as OrganizationEnrollmentV2;

    return useMemo(
        () => [
            entry?.step1Title || "Let's get started",
            entry?.step2Title || 'Tell us about your organization',
            entry?.step3Title || 'Set up your account',
        ],
        [entry]
    );
};
