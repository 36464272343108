import React, { useState, useMemo, useContext } from 'react';
import { History } from 'history';
import {
    faUserCircle,
    faWallet,
    faQuestionCircle,
    faShoppingBag,
    faHeart,
    IconDefinition,
} from '@fortawesome/pro-light-svg-icons';
import { useHistory } from 'react-router-dom';

import { Routes } from '../../routes';
import { useRootSelector } from 'common/features/featuresReducer';
import { sendToLogoutPage, sendToLoginPage } from 'features/auth/components/withAuth';
import { ReactComponent as NotificationBell } from '../../media/images/notif-bell.svg';
import { useNotifications, NotificationDataType } from 'hooks/useNotifications';
import { useDispatch } from 'react-redux';
import { updateAccount, UserAuthState } from 'common/features/store/duck/account/duck';
import { RunNewCoordinatorDashboardWelcomeMessageLogic } from 'utils';
import useFeatureFlags, {
    FeatureFlagTypes,
    useFeatureFlagWatcher,
} from 'common/hooks/useFeatureFlags';
import { selectIsP2PEnabled } from 'common/features/store/duck/ffs';
import { selectDefaultOrganization } from 'common/features/store/duck/home/duck';
import { P2pSources } from 'features/account-settings/components/SettingsTab/InviteFriendsFamily';

export interface NavMenuItem {
    text: string;
    type: 'item' | 'secondary-item' | 'separator';
    onSelect?: () => void;
    menuOptions?: NavMenuItem[];
    icon?: JSX.Element;
    styles?: string;
}

/*
 * The information about a nav button that is used to determine how the button is
 * rendered and its properties.
 *
 * name - the name of the button - this is used for uniqueness and to determine
 * which nav menu is currently open
 *
 * iconLabel - the text that should show up below the nav button
 *
 * icon (optional) - a reference to the FontAwesome icon that should be used as
 * the surface of the button. If no icon is provided, it's assumed that one is
 * specified in the determineButtonIcon function
 *
 * menuOptions - the list of options that should show up in the flyout when the
 * menu is open. If an empty array is provided, then the button will be rendered
 * as a direct link instead of a menu. In this instance it is expected that an onSelect
 * property is present
 *
 * onSelect (optional) - a function that specifies what should happen when the
 * nav button is clicked when there should be behavior that is different than the
 * typical behavior (which opens the flyout menu)
 *
 * footer (optional) - the react element that should show up at the bottom of the nav menu
 */
export interface NavButtonMetadata {
    id?: string;
    name: string;
    iconLabel: string;
    menuOptions: NavMenuItem[];
    icon?: IconDefinition;
    onSelect?: () => void;
    footer?: JSX.Element;
}

export interface NavState {
    setOpenMenu: React.Dispatch<React.SetStateAction<string>>;
    closeNavMenu: (after: () => void) => void;
    currentOpenMenu: string;
    navButtons: NavButtonMetadata[];
    notifications: {
        hasNotifications: boolean;
        notifications: NotificationDataType[];
    };
}

const initialState: NavState = {
    setOpenMenu: () => {},
    closeNavMenu: () => {},
    currentOpenMenu: '',
    navButtons: [],
    notifications: {
        hasNotifications: false,
        notifications: [],
    },
};

const NavMenuContext = React.createContext<NavState>(initialState);

export const NavMenuProvider = ({ children }: React.PropsWithChildren) => {
    useFeatureFlagWatcher();
    const { isEnabled } = useFeatureFlags();

    const [currentOpenMenu, setOpenMenu] = useState('');
    const { userId, isCoordinator } = useRootSelector((s) => s.store.account);
    const history = useHistory();
    const { hasNotifications, notifications } = useNotifications();
    const isP2pEnabled = useRootSelector(selectIsP2PEnabled);
    const defaultOrg = useRootSelector(selectDefaultOrganization);
    const orgHasP2pEnabled = Boolean(defaultOrg?.isP2PInviteEnabled);

    const navButtons = useMemo(
        () =>
            validationNavButtonMetaData(
                generateNavButtonMetadata(
                    history,
                    userId,
                    isCoordinator,
                    hasNotifications,
                    isEnabled(FeatureFlagTypes.enable_unified_coordinator_dashboard),
                    !!isP2pEnabled && orgHasP2pEnabled
                )
            ),
        [
            history,
            userId,
            isCoordinator,
            hasNotifications,
            isEnabled,
            isP2pEnabled,
            orgHasP2pEnabled,
        ]
    );
    const closeNavMenu = (after: () => void) => {
        setOpenMenu('');

        // This timeout lets the menu close animation finish before routing
        // Without this - the menu disappears and it looks weird
        setTimeout(() => {
            after();
        }, 175);
    };

    const contextState = {
        setOpenMenu,
        currentOpenMenu,
        navButtons,
        closeNavMenu,
        notifications: { hasNotifications, notifications },
    };

    return <NavMenuContext.Provider value={contextState}>{children}</NavMenuContext.Provider>;
};

export const useNavMenu = () => useContext(NavMenuContext);

export const useNewCoordInitialMessage = () => {
    const storageId = useRootSelector((s) => s.store.account.userId?.substr(0, 8));

    const maybeMarkInitialMessageAsSeen = () => {
        RunNewCoordinatorDashboardWelcomeMessageLogic(storageId || null);
    };

    return maybeMarkInitialMessageAsSeen;
};

const SignOutButton = () => {
    const dispatch = useDispatch<any>();
    // NOTE: Required by FEC-1221 & FEC-1174
    const maybeMarkInitialMessageAsSeen = useNewCoordInitialMessage();

    return (
        <button
            className="rounded-full border-brand border-2 text-brand text-base w-full text-center font-semibold cursor-pointer py-2 focus:outline-none"
            onClick={() => {
                dispatch(updateAccount({ userAuthState: UserAuthState.INITIAL }));
                sendToLogoutPage();
                maybeMarkInitialMessageAsSeen();
            }}
        >
            Sign Out
        </button>
    );
};

function validationNavButtonMetaData(metadata: NavButtonMetadata[]): NavButtonMetadata[] {
    metadata.forEach((navButton) => {
        if (navButton.name === 'cart') {
            return;
        }

        if (!navButton.menuOptions.length && !navButton.onSelect) {
            throw new Error(`Missing an onSelect handler for ${navButton.name} nav button`);
        }
    });

    return metadata;
}

function generateNavButtonMetadata(
    history: History,
    userId: string | null,
    isCoordinator: boolean,
    hasNotifications: boolean,
    isNewDashboard: boolean,
    isP2PEnabled: boolean
): NavButtonMetadata[] {
    const support: NavButtonMetadata = {
        name: 'support',
        iconLabel: 'Support',
        icon: faQuestionCircle,
        menuOptions: [
            {
                text: 'Contact Us',
                type: 'item',
                onSelect: () => {
                    window.location.href = Routes.Contact;
                },
            },
            {
                text: 'FAQs',
                type: 'item',
                onSelect: () => {
                    window.location.href = Routes.SupportFaq;
                },
            },
            {
                text: 'Coordinator Resources',
                type: 'item',
                onSelect: () => {
                    window.location.href = Routes.SupportOrganization;
                },
            },
            {
                text: 'Participant Resources',
                type: 'item',
                onSelect: () => {
                    window.location.href = Routes.SupportParticipant;
                },
            },
        ],
    };

    const shopNavData = {
        name: 'shop',
        iconLabel: 'Shop',
        icon: faShoppingBag,
        onSelect: () => {
            history.push(Routes.Brands);
        },
        menuOptions: [],
    };

    const unAuthShopNavData = {
        name: 'shop',
        iconLabel: 'Shop',
        icon: faShoppingBag,
        onSelect: () => {
            history.push(Routes.Shop);
        },
        menuOptions: [],
    };

    if (!userId) {
        return [
            unAuthShopNavData,
            {
                name: 'sign-in',
                iconLabel: 'Sign In',
                icon: faUserCircle,
                onSelect: () => sendToLoginPage(),
                menuOptions: [],
            },
            support,
        ];
    }

    return [
        shopNavData,
        {
            id: 'header_favorites_icon',
            name: 'favorites',
            iconLabel: 'Favorites',
            icon: faHeart,
            onSelect: () => {
                history.push(`${Routes.Favorites}`);
            },
            menuOptions: [],
        },
        {
            id: 'header_wallet_icon',
            name: 'wallet',
            iconLabel: 'Wallet',
            icon: faWallet,
            onSelect: () => {
                history.push(`${Routes.WalletMyCards}?section=my-cards`);
            },
            menuOptions: [],
        },
        {
            id: 'header_account_icon',
            name: 'account',
            iconLabel: 'Account',
            icon: faUserCircle,
            footer: <SignOutButton />,
            menuOptions: [
                isCoordinator && {
                    text: 'Coordinator Dashboard',
                    type: 'item',
                    icon: <>{hasNotifications && <NotificationBell />}</>,
                    onSelect: () =>
                        isNewDashboard
                            ? history.push(Routes.CoordinatorDashboardHome)
                            : history.push(Routes.ShopAccountSettingsCoordinatorTab),
                },
                {
                    text: 'Personal Dashboard',
                    type: 'item',
                    onSelect: () => {
                        history.push(Routes.ShopAccountSettingsDashboardTab);
                    },
                },
                {
                    text: 'Order History',
                    type: 'item',
                    onSelect: () => {
                        history.push(Routes.ShopAccountSettingsOrderHistoryTab);
                    },
                },
                {
                    text: 'Settings',
                    type: 'item',
                    onSelect: () => {
                        history.push(Routes.ShopAccountSettingsPersonalInformationTab);
                    },
                },
                isP2PEnabled && {
                    text: 'Invite Friends & Family',
                    type: 'item',
                    onSelect: () => {
                        history.push(
                            `${Routes.ShopAccountSettingsInviteFriendsFamilyOptionsTab}?source=${P2pSources.Header}`
                        );
                    },
                    styles: 'sm:hidden',
                },
                {
                    text: '',
                    type: 'separator',
                },
                {
                    text: 'Contact Us',
                    type: 'secondary-item',
                    onSelect: () => {
                        window.location.href = Routes.Contact;
                    },
                },
                {
                    text: 'FAQs',
                    type: 'secondary-item',
                    onSelect: () => {
                        window.location.href = Routes.SupportFaq;
                    },
                },
                {
                    text: 'Coordinator Resources',
                    type: 'secondary-item',
                    onSelect: () => {
                        window.location.href = Routes.SupportOrganization;
                    },
                },
                {
                    text: 'Participant Resources',
                    type: 'secondary-item',
                    onSelect: () => {
                        window.location.href = Routes.SupportParticipant;
                    },
                },
            ].filter(Boolean) as NavMenuItem[],
        },
        {
            name: 'cart',
            iconLabel: 'Cart',
            menuOptions: [],
        },
    ];
}
